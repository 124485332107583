import { Injectable } from '@angular/core';

@Injectable()
export class PDFService {
  
  pdfMake: any;

  constructor() { }

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }

  async generatePdf(def) {
    await this.loadPdfMaker();
    
    this.pdfMake.createPdf(def).download("export.pdf");
  }
  public getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "*");
      
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
  public resizeImageFromBase64(sourceBase64, scale) {
    return new Promise((resolve, reject) => {
      const _scale = scale;
      var img = document.createElement('img');
      img.setAttribute("src", sourceBase64);

      img.onload = () => {
        var canvas = document.createElement('canvas');
          canvas.width = img.width * _scale;
          canvas.height = img.height * _scale;

          var ctx = canvas.getContext("2d");
          var cw = canvas.width;
          var ch = canvas.height;
          var maxW = img.width * _scale;
          var maxH = img.height * _scale;

          var iw = img.width;
          var ih = img.height;
          var scl = Math.min((maxW / iw), (maxH / ih));
          var iwScaled = iw * scl;
          var ihScaled = ih * scl;
          canvas.width = iwScaled;
          canvas.height = ihScaled;
          ctx.drawImage(img, 0, 0, iwScaled, ihScaled);

          var dataURL = canvas.toDataURL("image/jpeg", 1.0);
          
          resolve(dataURL);
      }
      img.onerror = error => {
        reject(error);
      };
    });
  }
}