import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class DeviceService {
  public view : any = {};
  public devices : any[] = [];

  public apiUrl : string = "https://dashboard.ice-neo.co.za";//"http://10.83.6.53:8080";//"https://dashboard.ice-neo.co.za";

  @Output() public deviceChange: EventEmitter<string> = new EventEmitter();
  @Output() public deviceStateChange: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    
    this.validateUser();
  }

  public clearBrand() {
    localStorage.removeItem("bc");
  }
  public setBrand(brandCode) {
    if (brandCode == "r")
      this.clearBrand();
    else
      localStorage.setItem("bc", brandCode);
  }
  public getBrand() {
    var item : any = {
      company:"iCE",
      product:"NEO",
      logo: "logo.png"
    };

    var brandCode = localStorage.getItem("bc");

    if (brandCode == "curovis") {
      item = {
        company:"",
        product:"Curovis",
        logo: "logo-curovis.png"
      };
    }
    else if (brandCode == "cci") {
      item = {
        company:"",
        product:"CCI",
        logo: "logo-cci.png"
      };
    }

    return item;
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  
  public setCurrentDeviceID(deviceID) {
    this.view.currentDeviceID = deviceID;

    this.deviceChange.emit(this.view.currentDeviceID);
  }
  public getCurrentDeviceID() {
    return this.view.currentDeviceID;
  }

  public getServicesStatus() {
    var services = [];

    services.push({name:"NEO Dashboard", description:"", state:{status:"success",text:"Nothing to report"}});
    services.push({name:"NEO API Service", description:"", state:{status:"success",text:"Nothing to report"}});
    // services.push({name:"NEO WebSocket Service", description:"", state:{status:"success",text:"Nothing to report"}});
    services.push({name:"NEO MQTT Service", description:"", state:{status:"success",text:"Nothing to report"}});
    services.push({name:"NEO Datastore Service", description:"", state:{status:"success",text:"Nothing to report"}});

    return services;
  }

  public publishMQTT(topic, payload) {
    return this.http.post('/api/mqtt/publish', {topic:topic, payload:payload}).toPromise().then((res:any) => {
      return res;
    })
    .catch(err=>{
      return {success:false};
    });
  }

  public getServicesState() {
    var apiIsUp = false;

    return this.http.get('/api/services/state').toPromise().then((res:any) => {
      apiIsUp = true;

      if (res.success && res.services) {
        res.services.push({service:"API", isUp:apiIsUp, state:"active", message:""});
      }

      return res;
    })
    .catch(err=>{
      apiIsUp = false;

      return {success:false, services:[{service:"API", isUp:apiIsUp, state:"down", message:""}]};
    });
  }

  //Weather API
  public keyWeather = "bf83200eba91499387b121415220509";
  public getWeatherForecast(lat,lng) {
    //https://api.weatherapi.com/v1/forecast.json?key=bf83200eba91499387b121415220509&q=-33.924870,18.424055&days=5&aqi=no&alerts=no
    //var url = `http://ice-api.travelfind.me/api/weather/${lat}/${lng}`; //`https://api.weatherapi.com/v1/forecast.json?key=${this.keyWeather}&q=${lat},${lng}&days=5&aqi=no&alerts=no`;
    var url = `${this.apiUrl}/api/weather/${lat}/${lng}`;
    return this.http.get(url).toPromise().then((response:any) => {
      return response;
    }).catch(this.error);
  }

  //Loadshedding API
  public keyLoadshedding = "2DA2C964-BEE8432C-9DECDB5B-8005C533";
  public getLoadsheddingAreas(lat,lng) {
    var url = `${this.apiUrl}/api/loadshedding/${lat}/${lng}`;
    return this.http.get(url).toPromise().then((response:any) => {
      return response;
    }).catch(this.error);
  }
  public getLoadsheddingAreaForecast(areaId) {
    var url = `${this.apiUrl}/api/loadshedding/${areaId}`;
    return this.http.get(url).toPromise().then((response:any) => {
      return response;
    }).catch(this.error);
  }
  deviceLoadshedArea(device, lsAreaId): Promise<void | any> {
    return this.http.put('/api/devices/loadshedding/' + device, {areaId: lsAreaId})
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }

  //Forge
  getAccessToken(): Promise<void | any> {
    const body = new HttpParams()
    .set('client_id', 'CsSXd4YPFXTHgzFBJruYGcfJROZooMJN')
    .set('client_secret', 'o3AC8RU8iIEkmksz')
    .set('grant_type', 'client_credentials')
    .set('scope', 'data:read');

    return this.http.post('https://developer.api.autodesk.com/authentication/v1/authenticate', body.toString(), { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') })
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }
  //Forge

  getRemoteFile(url, responseType = null): Promise<void | any> {
    if (responseType){
      return this.http.get(url, {responseType: responseType}).toPromise().then((response:any) => {
        return response;
      }).catch(this.error);
    }
    else {
      return this.http.get(url).toPromise().then((response:any) => {
        return response;
      }).catch(this.error);
    }
  }

  getCompanyUsers(company): Promise<void | any> {
    return this.http.get('/api/users/' + company)
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }
  userAuth(username, password): Promise<void | any> {
    return this.http.post('/api/user/auth', {username: username, password: password})
               .toPromise()
               .then((response:any) => {
                 this.setLoggedinUser(response);

                 return response;
                })
               .catch(this.error);
  }
  userAuthToken(token): Promise<void | any> {
    return this.http.get('/api/user/auth/token/' + token)
               .toPromise()
               .then((response:any) => {
                 this.setLoggedinUser(response);

                 return response;
                })
               .catch(this.error);
  }

  getDevicesChanged(): Promise<void | any> {
    return this.http.get('/api/devices/changed')
               .toPromise()
               .then((response:any) => {
                if (response.hasChanges) {
                  this.getDevices().then(res => {
                    this.deviceStateChange.emit(res);
                  });
                }

                return response;
               })
               .catch(this.error);
  }

  getDevices(): Promise<void | any> {
    return this.http.get('/api/devices')
               .toPromise()
               .then((response:any[]) => {
                this.devices = response;
                return this.filterDevices();
               })
               .catch(this.error);
  }

  getDevicePointsAll(d_id): Promise<void | any> {
    return this.http.get('/api/devices/' + d_id.toLowerCase() + "/points")
               .toPromise()
               .then((response:any[]) => {
                return response;
               })
               .catch(this.error);
  }
  //http://ice-api.travelfind.me/api/devices/aaa/floor/0/points/aaa
  getDevicePoints(d_id, floor, point): Promise<void | any> {
    return this.http.get('/api/devices/' + d_id.toLowerCase() + "/floor/" + floor + "/points/" + point.toLowerCase())
               .toPromise()
               .then((response:any[]) => {
                return response;
               })
               .catch(this.error);
  }
  storeDevicePoint(d_id, floor, point, data, states = null, friendlyName = null): Promise<void | any> {
    return this.http.post('/api/devices/' + d_id.toLowerCase() + "/floor/" + floor + "/points/" + point.toLowerCase(), {data: data, states: states, friendlyName:friendlyName})
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }

  getFileExist(fileName, extension = "png"): Promise<void | any> {
    return this.http.get('/api/devices/file/' + fileName + '/exist/' + extension)
               .toPromise()
               .then((response:any) => {
                 return response;
               })
               .catch(this.error);
  }
  deleteFile(fileName, extension = "png"): Promise<void | any> {
    return this.http.get('/api/devices/file/' + fileName + '/delete/' + extension)
               .toPromise()
               .then((response:any) => {
                 return response;
               })
               .catch(this.error);
  }
  getFile(fileName, fileType = "png") {
    //return 'http://ice-api.travelfind.me/api/devices/file/' + fileName + "/" + fileType;
    return this.apiUrl + '/api/devices/file/' + fileName + "/" + fileType;
  }

  storePointLocations(fileName, data): Promise<void | any> {
    return this.http.post('/api/devices/points/' + fileName, {data: data})
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }
  getPointLocations(fileName): Promise<void | any> {
    return this.getRemoteFile('/api/devices/points/' + fileName, "json");
  }
  getAlerts(): Promise<void | any> {
    return this.http.get('/api/alerts/')
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }
  getAlertsForUser(userId): Promise<void | any> {
    return this.http.get('/api/alerts/user/' + userId)
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }
  getAlertsForPoint(pointId): Promise<void | any> {
    return this.http.get('/api/alerts/point/' + pointId)
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }
  createAlert(data): Promise<void | any> {
    return this.http.post('/api/alerts', data)
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }
  updateAlert(id, data): Promise<void | any> {
    return this.http.put('/api/alerts/' + id, data)
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }

  // InfluxDB calls
  queryInfluxV2(query, db): Promise<void | any> {
    return this.http.post('/api/influx/query_v2', {query: query, db: db})
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }

  //eg: http://ice-neo.co.za:8086/query?q=SELECT FIRST(value) FROM "TEMP" WHERE time>'2021-12-03T05:00:00Z' GROUP BY time(5m),"value"&db=MQTT_Room_MQTT000000PVDB01
  queryInflux(query, db): Promise<void | any> {
    return this.http.post('/api/influx/query', {query: query, db: db})
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }

  multipleQueryInfluxV2(queries): Promise<void | any> {
    return this.http.post('/api/influx/queriesApi', {queries: queries})
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }
  multipleQueryInflux(queries): Promise<void | any> {
    return this.http.post('/api/influx/queries', {queries: queries})
               .toPromise()
               .then((response:any) => {
                 return response;
                })
               .catch(this.error);
  }
  
  getMeasurementFields(database:string, measurement:string = null, tag:string = null) {
    //return this.queryInfluxV2(`SHOW FIELD KEYS ON "${this.view.device.data.d_comp}" FROM "${this.view.device.db}"`, this.view.device.db);
    if (!measurement && !tag)
      return this.queryInfluxV2(`SHOW MEASUREMENTS ON "${database}"`, database); //show measurements on "Liberty" where device_type='LLCDataCenter'
    else if (tag)
      return this.queryInfluxV2(`SHOW MEASUREMENTS ON "${database}" WHERE device_type='${tag}'`, tag); //show measurements on "Liberty" where device_type='LLCDataCenter'
    else if (measurement)
      return this.queryInfluxV2(`SHOW FIELD KEYS ON "${database}" FROM "${measurement}"`, measurement);
  
  }
  getMeasurementFieldLatestData(database:string, measurement:string, field:string = "*", filter:string = "", limit:number = 40) {
    if (field == "*") {
      //return this.queryInfluxV2(`SELECT * FROM "${database}"."default"."${measurement}" WHERE time>=now()-5m FILL(previous)`, measurement);
      return this.queryInfluxV2(`SELECT * FROM "${database}"."default"."${measurement}" FILL(previous) ORDER BY time DESC LIMIT ${measurement.toLowerCase() == "kodak" ? limit * 2 : limit}`, measurement);
    }
    else {
      //return this.queryInfluxV2(`SELECT MEAN("${field}") AS value FROM "${database}"."default"."${measurement}" WHERE time>=now()-10m GROUP BY time(30s) FILL(previous)`, measurement);
      //return this.queryInfluxV2(`SELECT MEAN("${field}") AS value FROM "${database}"."default"."${measurement}" GROUP BY time(30s) FILL(previous) ORDER BY time DESC LIMIT 1`, measurement);
      return this.queryInfluxV2(`SELECT "${field}" AS value FROM "${database}"."default"."${measurement}" FILL(previous) ORDER BY time DESC LIMIT 1`, measurement);
    }
  }

  buildOCQueryV2(floor:number, id:string, area:number, field:string = 'people_counter_all', prefix:string = 'OC', type:string = 'Occupancy') {
    var query = `SELECT ("${field}") AS "count_value" FROM "Liberty"."default"."${prefix}-${floor}-${id}" WHERE "device_type"='${type}' ORDER BY DESC LIMIT 1`;
    var db = `Liberty-${prefix}-${floor}-${id}`; //"Liberty-OC-1-003";

    return {
      query: query,
      db: db,
      floor: floor,
      area:area,
      id: id
    }
  }

  buildOCQuery(floor:number, id:string, area:number) {
    //var query = "SELECT (\"value\") AS \"count_value\" FROM \"people_counter_all\" GROUP BY time(20m) FILL(previous) ORDER BY DESC LIMIT 1";
    //var query = "SELECT (\"value\") AS \"count_value\" FROM \"people_max\" GROUP BY time(20m) FILL(previous) ORDER BY DESC LIMIT 1";
    //var query = "SELECT \"value\" AS \"count_value\" FROM \"people_counter_all\" FILL(previous) ORDER BY DESC LIMIT 1";
    var query = "SELECT \"value\" AS \"count_value\" FROM \"people_counter_all\" ORDER BY DESC LIMIT 1";
    //-var query = "SELECT \"value\" AS \"count_value\" FROM \"people_max\" FILL(previous) ORDER BY DESC LIMIT 1";
    var db = `Liberty-OC-${floor}-${id}`; //"Liberty-OC-1-003";

    return {
      query: query,
      db: db,
      floor: floor,
      area:area,
      id: id
    }
  }
  
  buildQueryTimeV2(floor:number, id:string, area:string, groupByTime:string, dateStart:Date, dateEnd:Date, field:string = 'people_counter_all', aggregate:string = 'max') {
    var query = `SELECT ${aggregate}("${field}") AS "count_value" FROM "Anglo"."default"."${area}" WHERE "time" >= '${dateStart.toISOString()}' AND "time" <= '${dateEnd.toISOString()}' GROUP BY time(${groupByTime}) FILL(previous)`;
    var db = `${field}`; //"Liberty-OC-1-003";

    return {
      query: query,
      db: db,
      floor: floor,
      area:area,
      id: id
    }
  }

  buildOCQueryTimeV2(floor:number, id:string, area:number, groupByTime:string, dateStart:Date, dateEnd:Date, field:string = 'people_counter_all', prefix:string = 'OC', type:string = 'Occupancy', aggregate:string = 'max') {
    var query = `SELECT ${aggregate}("${field}") AS "count_value" FROM "Liberty"."default"."${prefix}-${floor}-${id}" WHERE "device_type"='${type}' AND "time" >= '${dateStart.toISOString()}' AND "time" <= '${dateEnd.toISOString()}' GROUP BY time(${groupByTime}) FILL(previous)`;
    var db = `Liberty-${prefix}-${floor}-${id}`; //"Liberty-OC-1-003";

    return {
      query: query,
      db: db,
      floor: floor,
      area:area,
      id: id
    }
  }

  buildOCQueryTime(floor:number, id:string, area:number, groupByTime:string, dateStart:Date, dateEnd:Date, aggregate:string = "max") {
    //var query = "SELECT (\"value\") AS \"count_value\" FROM \"people_counter_all\" GROUP BY time(20m) FILL(previous) ORDER BY DESC LIMIT 1";
    //var query = "SELECT (\"value\") AS \"count_value\" FROM \"people_max\" GROUP BY time(20m) FILL(previous) ORDER BY DESC LIMIT 1";
    //var query = "SELECT \"value\" AS \"count_value\" FROM \"people_counter_all\" FILL(previous) ORDER BY DESC LIMIT 1";
    var query = "SELECT "+ aggregate + "(\"value\") AS \"count_value\" FROM \"people_counter_all\" WHERE time >= '" + dateStart.toISOString() + "' and time <= '" + dateEnd.toISOString() + "' GROUP BY time(" + groupByTime + ") FILL(previous)";
    //-var query = "SELECT \"value\" AS \"count_value\" FROM \"people_max\" FILL(previous) ORDER BY DESC LIMIT 1";
    var db = `Liberty-OC-${floor}-${id}`; //"Liberty-OC-1-003";

    return {
      query: query,
      db: db,
      floor: floor,
      area:area,
      id: id
    }
  }

  buildTempQuery(floor:number, id:string, area:number) {
    //var query = "SELECT (\"value\") AS \"count_value\" FROM \"people_counter_all\" GROUP BY time(20m) FILL(previous) ORDER BY DESC LIMIT 1";
    //var query = "SELECT (\"value\") AS \"count_value\" FROM \"people_max\" GROUP BY time(20m) FILL(previous) ORDER BY DESC LIMIT 1";
    //var query = "SELECT \"value\" AS \"count_value\" FROM \"people_counter_all\" FILL(previous) ORDER BY DESC LIMIT 1";
    var query = "SELECT \"value\" AS \"count_value\" FROM \"value\" ORDER BY DESC LIMIT 1";
    //-var query = "SELECT \"value\" AS \"count_value\" FROM \"people_max\" FILL(previous) ORDER BY DESC LIMIT 1";
    var db = `Liberty-OHS-${floor}-T${id}`; //"Liberty-OHS-3-T121";

    return {
      query: query,
      db: db,
      floor: floor,
      area:area,
      id: id
    }
  }

  //SELECT count("value") AS "count_value" FROM "Liberty-OC-1-003"."default"."people_counter_all" WHERE time > :dashboardTime: GROUP BY time(:interval:) FILL(0) ORDER BY DESC LIMIT 1
  queryOCCount(floor:number, id:string) {
    var rs = new ReplaySubject();

    //var query = "SELECT (\"value\") AS \"count_value\" FROM \"people_counter_all\" GROUP BY time(20m) FILL(previous) ORDER BY DESC LIMIT 1";
    //var query = "SELECT (\"value\") AS \"count_value\" FROM \"people_max\" GROUP BY time(20m) FILL(previous) ORDER BY DESC LIMIT 1";
    //var query = "SELECT \"value\" AS \"count_value\" FROM \"people_counter_all\" FILL(previous) ORDER BY DESC LIMIT 1";
    var query = "SELECT \"value\" AS \"count_value\" FROM \"people_counter_all\" ORDER BY DESC LIMIT 1";
    //-var query = "SELECT \"value\" AS \"count_value\" FROM \"people_max\" FILL(previous) ORDER BY DESC LIMIT 1";
    var db = `Liberty-OC-${floor}-${id}`; //"Liberty-OC-1-003";
    
    this.queryInflux(query, db).then(res => {
      if (res && res.results) {
        if (res.results[0].series) {
          if (res.results[0].series[0]) {
            if (res.results[0].series[0].values) {
              rs.next(res.results[0].series[0].values[0][1]);
            }
          }
        }
      }

      rs.next(0);
    });

    return rs;
  }

  getAlertLogs(from, to): Promise<void | any> {
    return this.http.get('/api/alert/logs/' + from + "/" + to)
               .toPromise()
               .then((response:any[]) => {
                return response;
               })
               .catch(this.error);
  }

  // Error handling
  private error (error: any) {
    let message = (error.message) ? error.message :
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(message);
  }

  setLoggedinUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  getLoggedinUser():any {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));

      if (user) {
        if (!user.user_company_original)
          user.user_company_original = user.user_company;
        if (!user.user_rights_original)
          user.user_rights_original = user.user_rights;

        user.isAdmin = ((user.user_company.toLowerCase() == 'ice') && (user.user_rights.toLowerCase() == 'admin'));
        user.isSuperUser = (user.user_rights.toLowerCase() == 'superuser');

        if (!user.isAdmin_original)
          user.isAdmin_original = user.isAdmin;

        return user;
      }
    }
    
    console.log("NO USER FOUND");
    return false;
  }
  logoutUser() {
    localStorage.removeItem('user');
    this.validateUser();
  }
  viewAs(company, rights = 'user') {
    var user = this.getLoggedinUser();
    user.user_company = company;
    user.user_rights = rights;

    this.setLoggedinUser(user);

    window.location.reload();
  }

  validateUser() {
    if (!this.getLoggedinUser()) {
      //this.router.navigate(['/login']);
    }
    else
      return this.getLoggedinUser();
  }
  redirectToDashboard() {
    if (this.validateUser()) {
      if (this.getLoggedinUser() && this.getLoggedinUser().user_company.toLowerCase() == "sanlam") {
        this.router.navigate(['/app/main/alerts']);
      }
      else {
        this.router.navigate(['/app/main/overview']);
      }
    }
  }
  isViewMode() {
    let v = 0;
    if (localStorage.getItem("viewMode")) {
      v = Number(localStorage.getItem("viewMode"));
    }

    return v;
  }

  generateCSV = (filename: string, rows: object[], headers?: string[]): void => {
    if (!rows || !rows.length) {
        return;
    }
    const separator: string = ",";

    const keys: string[]  = Object.keys(rows[0]);

    let columHearders: string[];

    if (headers) {
        columHearders = headers;
    } else {
        columHearders = keys;
    }

    const csvContent =
        "sep=,\n" +
        columHearders.join(separator) +
        '\n' +
        rows.map(row => {
            return keys.map(k => {
                let cell = row[k] === null || row[k] === undefined ? '' : row[k];

                cell = cell instanceof Date 
                    ? cell.toLocaleString()
                    : cell.toString().replace(/"/g, '""');

                if ((<any>navigator).msSaveBlob) {
                    cell = cell.replace(/[^\x00-\x7F]/g, ""); //remove non-ascii characters
                }
                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator);
        }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if ((<any>navigator).msSaveBlob) { // In case of IE 10+
      (<any>navigator).msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  }

  filterDevices() {
    let devices : any = {};

    var user = this.validateUser();
    if (user) {
      if (user.user_company.toLowerCase() == 'ice' || user.user_company.toLowerCase() == 'liberty') {
        //Add dummy device
        this.devices.push({
          d_add: "Liberty HO Braamfontein",
          d_class: "A",
          d_comp: "Liberty",
          d_conn_status: "Online",
          d_exp_date: "2022-10-12T22:00:00.000Z",
          d_health: "Healthy",
          d_id: "Building_MQTTLiberty", //"Building_MQTT000144Oxford",
          d_lat: "-26.142520387088574",
          d_long: "28.043913684657472",
          d_name: "Liberty HO Braamfontein",
          d_profile_id: "MQTT",
          d_prov: "Gauteng",
          d_start_date: "2021-10-12T22:00:00.000Z",
          d_type: "Building",
          dashboard_id: null,
          b_inNav:1,
          s_no: "MQTTLiberty",//"MQTT000144Oxford",
          d_lsAreaId: "jhbcitypower2-6-braamfontein",
          isActive: true
        });

        //Add dummy EVRack //no id yet
        // devices.all.push({
        //   d_add: "Boksburg",
        //   d_class: "A",
        //   d_comp: "iCE",
        //   d_conn_status: "Online",
        //   d_exp_date: "2022-10-12T22:00:00.000Z",
        //   d_health: "Healthy",
        //   d_id: "ev_", //"Building_MQTT000144Oxford",
        //   d_lat: "-26.232590",
        //   d_long: "28.240967",
        //   d_name: "iCE EV Rack",
        //   d_profile_id: "MQTT",
        //   d_prov: "Gauteng",
        //   d_start_date: "2021-10-12T22:00:00.000Z",
        //   d_type: "EV",
        //   dashboard_id: null,
        //   s_no: "Lib_Branch_Boksburg",//"MQTT000144Oxford",
        // });
      }

      if (user.user_company.toLowerCase() != 'ice')
        devices.all = this.devices.filter(x => x.isActive &&
          (x.d_comp.toLowerCase() == user.user_company.toLowerCase() || x.d_comp2.toLowerCase() == user.user_company.toLowerCase()) && 
          (!user.deviceExclude || (user.deviceExclude && user.deviceExclude.toLowerCase().indexOf(x.d_id.toLowerCase()) < 0)) &&
          (!user.deviceInclude || (user.deviceInclude && user.deviceInclude.toLowerCase().indexOf(x.d_id.toLowerCase()) >= 0))
          );
      else 
        devices.all = this.devices.filter(x=>x.isActive);

      devices.all.sort((a, b) => a.d_name.localeCompare(b.d_name));
      devices.all.forEach(device => device = this.setDeviceStatus(device));
      //console.log("devices", devices, user);

      //Get devices based on status
      devices.critical = devices.all.filter(x=>x.d_health.toLowerCase() == "critical" && x.d_conn_status.toLowerCase() == "online") || [];
      devices.warning = devices.all.filter(x=>x.d_health.toLowerCase() == "warning" && x.d_conn_status.toLowerCase() == "online") || [];
      devices.healthy = devices.all.filter(x=>x.d_health.toLowerCase() == "healthy" && x.d_conn_status.toLowerCase() == "online") || [];
      devices.offline = devices.all.filter(x=>x.d_conn_status.toLowerCase() == "offline" || x.d_conn_status.toLowerCase() == "awaiting validation") || [];
      
      //Group devices by d_prov
      devices.regions = [];
      devices.all.forEach(device => {
        if (devices.regions.filter(x=>x.name.toLowerCase() == device.d_prov.toLowerCase()).length == 0)
          devices.regions.push({name:device.d_prov, devices:[]});

        devices.regions.filter(x=>x.name.toLowerCase() == device.d_prov.toLowerCase())[0].devices.push(device);
      });
    }

    return devices;
  }
  public setDeviceStatus(device) {
    var connStatus = device.data ? device.data.d_conn_status.toLowerCase() : device.d_conn_status.toLowerCase();
    var connHealth = device.data ? device.data.d_health.toLowerCase() : device.d_health.toLowerCase();

    if (connStatus == "online")
      device.stateConnection = "success";
    else if (connStatus == "offline")
      device.stateConnection = "danger";
    else if (connStatus == "awaiting validation")
      device.stateConnection = "warning";

    if (connHealth == "critical")
      device.stateStatus = "danger";
    else if (connHealth == "warning")
      device.stateStatus = "warning";
    else if (connHealth == "healthy")
      device.stateStatus = "success";
    else if (connHealth == "unknown")
      device.stateStatus = "info";

    return device;
  }
  public deviceStateChanges(devicesCurrent, devicesPrevious) {
    if (devicesCurrent && devicesPrevious.all) {
      if (devicesCurrent.critical && devicesPrevious) {
        devicesCurrent.critical.forEach(device => {
          var devicePrevious = devicesPrevious.all.filter(x=>x.d_id == device.d_id && (x.d_health != device.d_health || x.d_conn_status != device.d_conn_status));
          if (devicePrevious.length > 0) {
            device.stateChanged = true;
            device.statePrevious = devicePrevious[0];
          }
        });
      }
      if (devicesCurrent.warning && devicesPrevious) {
        devicesCurrent.warning.forEach(device => {
          var devicePrevious = devicesPrevious.all.filter(x=>x.d_id == device.d_id && (x.d_health != device.d_health || x.d_conn_status != device.d_conn_status));
          if (devicePrevious.length > 0) {
            device.stateChanged = true;
            device.statePrevious = devicePrevious[0];
          }
        });
      }
      if (devicesCurrent.healthy && devicesPrevious) {
        devicesCurrent.healthy.forEach(device => {
          var devicePrevious = devicesPrevious.all.filter(x=>x.d_id == device.d_id && (x.d_health != device.d_health || x.d_conn_status != device.d_conn_status));
          if (devicePrevious.length > 0) {
            device.stateChanged = true;
            device.statePrevious = devicePrevious[0];
          }
        });
      }
      if (devicesCurrent.offline && devicesPrevious) {
        devicesCurrent.offline.forEach(device => {
          var devicePrevious = devicesPrevious.all.filter(x=>x.d_id == device.d_id && (x.d_health != device.d_health || x.d_conn_status != device.d_conn_status));
          if (devicePrevious.length > 0) {
            device.stateChanged = true;
            device.statePrevious = devicePrevious[0];
          }
        });
      }
    }

    return devicesCurrent;
  }
  isAvailablePerDeviceType(device:any, compare = "") {
    var types = [];

    if (!device.data || !device.data.d_type)
      return false;

    if (device.data.d_type.toLowerCase() == "room" || device.data.d_type.toLowerCase() == "building" || device.data.d_type.toLowerCase() == "power monitoring") {
      types = [
        "temp", "temperature", 
        "humi", "humidity",
        "extpwr",
        "di0",
        "di1",
        "di2",
        "di3",
        "di4",
        "di5",
        "di6",
        "di7",
        "co2",
        "tvoc",
        "illumination",
        "pressure"
      ]
    }
    else if (device.data.d_type.toLowerCase() == "single phase energy meter") {
      types = [
        "voltage".toLowerCase(),
        "TotalEnergy".toLowerCase(),
        "Current".toLowerCase(),
        "PowerFactor".toLowerCase(),
        "Frequency".toLowerCase(),
        "ActivePower".toLowerCase(),
        "MaxDemandPower".toLowerCase()
      ]
    }
    else if (device.data.d_type.toLowerCase() == "energy meter") {
      types = [
        "Frequency".toLowerCase(), //49.97178649902344,
        "LineVoltageL1_2".toLowerCase(), //229.51522827148438,
        "LineVoltageL2_3".toLowerCase(), //0,
        "LineVoltageL3_1".toLowerCase(), //229.14212036132812,
        "MeterSN".toLowerCase(), //23690065,
        "TotalActiveEnergy".toLowerCase(), //0.0020000000949949026,
        "TotalPowerFactor".toLowerCase(), //0
        "ActivePower1".toLowerCase(), //0
        "ActivePower2".toLowerCase(), //0,
        "ActivePower3".toLowerCase(), //0,
        "Current1".toLowerCase(), //0,
        "Current2".toLowerCase(), //0,
        "Current3".toLowerCase(), //0,
        "Voltage1".toLowerCase(), //231.6645965576172,
        "Voltage2".toLowerCase(), //0,
        "Voltage3".toLowerCase(), //0

        "Phase1 Active Power".toLowerCase(),
        "Phase1 Current".toLowerCase(),
        "Phase1 Voltage".toLowerCase(),
        "Phase2 Active Power".toLowerCase(),
        "Phase2 Current".toLowerCase(),
        "Phase2 Voltage".toLowerCase(),
        "Phase3 Active Power".toLowerCase(),
        "Phase3 Current".toLowerCase(),
        "Phase3 Voltage".toLowerCase(),
        "System Power Factor".toLowerCase(),
        "Total System Active Power(KA)".toLowerCase(),
        "Total System Active Power(KW)".toLowerCase(),
        "Energy".toLowerCase()
      ]
    }
    else if (device.data.d_type.toLowerCase() == "water meter") {
      types = [
        "WM_101_goldrush".toLowerCase(),
        "WM_102_goldrush".toLowerCase(),
        "WM_103_wits".toLowerCase(),
        "WM_104_forest".toLowerCase(),
        "WM_105_hustle".toLowerCase(),
        "WM_106_hustle".toLowerCase(),
        "WM_201_goldrush".toLowerCase(),
        "WM_202_goldrush".toLowerCase(),
        "WM_203_wits".toLowerCase(),
        "WM_204_forest".toLowerCase(),
        "WM_205_hustle".toLowerCase(),
        "WM_206_hustle".toLowerCase(),
        "WM_304_forest".toLowerCase(),
        "WM_305_hustle".toLowerCase(),
        "WM_306_hustle".toLowerCase(),
        "WM_401_hustle".toLowerCase(),
        "WM_402_hustle".toLowerCase()
      ]
    }
    else if (device.data.d_type.toLowerCase() == "water tank") {
      types = [
        "water_level".toLowerCase(),
        "percentage_value".toLowerCase()
      ]
    }
    else if (device.data.d_type.toLowerCase() == "ups") {
      types = [
        "Input Voltage".toLowerCase(),
        "Ouput Voltage".toLowerCase(),
        "Output Load".toLowerCase(),
        "Battery Voltage".toLowerCase(),
        "Battery Capacity".toLowerCase(),
        "Frequency".toLowerCase(),
        "UPS Battery Status".toLowerCase(),
        "UPS Output Source".toLowerCase(),
        "Time on Battery".toLowerCase()
      ]
    }
    else if (device.data.d_type.toLowerCase() == "powerups") {
      types = [
        "UPS_Model_Name".toLowerCase(),
        "UPS_Serial_Number".toLowerCase(),
        "UPS_Firmware_Version".toLowerCase(),
        "UPS_Type".toLowerCase(), //0   Standy, 1   line-interactive, 2   Online
        "UPS_Battery_Status".toLowerCase(), //1    Unknown, 2    Battery Normal, 3    Battery Low, 4    Battery Depleted, 5    Battery Discharging, 6    Battery Failure, 7    Battery Replace, 8    Battery silence        
        "UPS_Battery_Temperature".toLowerCase(), //°C
        "Batteries_Quantity_Series".toLowerCase(), //None
        "UPS_Battery_Capacity".toLowerCase(), //%
        "UPS_Battery_Voltage".toLowerCase(), //V
        "UPS_Input_Frequency".toLowerCase(), //HZ
        "UPS_Input_Voltage".toLowerCase(), //V
        "UPS_Output_Source".toLowerCase(), //1    other, 2    none, 3    normal, 4    bypass, 5    battery, 6    booster, 7    reducer, 8    battery test, 9    fault, 10   HE-ECOmode, 11   convertermode        
        "UPS_Load_Apparent_Power".toLowerCase(), //kVA
        "UPS_Load_Active_Power".toLowerCase(), //KW
        "UPS_Output_Voltage".toLowerCase(), //V
        "UPS_Output_Current".toLowerCase(), //A
        "UPS_Output_Percent_Load".toLowerCase(), //%
        "UPS_Bypass_Voltage".toLowerCase(), //V
        "UPS_Bypass_Current".toLowerCase() //
      ]
    }
    else if (device.data.d_type.toLowerCase() == "evrack") {
      types = [
        //Energy Meter
        // "REG20128".toLowerCase(), //Voltage, 
        // "REG20130".toLowerCase(), //Current,  
        // "REG20132".toLowerCase(), //Active Power,  
        // "REG20134".toLowerCase(), //Power Factor,  
        // "REG20136".toLowerCase(), //Frequency,  
        // "REG20138".toLowerCase(), //Total Energy,
        // "REG20140".toLowerCase(), //Maximum total system power demand
        "Voltage".toLowerCase(), //Voltage, 
        "Current".toLowerCase(), //Current,  
        "ActivePower".toLowerCase(), //Active Power,  
        "PowerFactor".toLowerCase(), //Power Factor,  
        "Frequency".toLowerCase(), //Frequency,  
        "TotalActiveEnergy".toLowerCase(), //Total Energy,
        //"REG20140".toLowerCase(), //Maximum total system power demand

        //Rack / AC
        "DO0".toLowerCase(), //0 = EV rack Front door closed, 1 =  EV rack Back door Open
        "REG20000".toLowerCase(), //EV Rack Temperature
        "REG64".toLowerCase(), //Working status machine (0:Off, 1:On)
        "REG65".toLowerCase(), //Internal Fan status (0:Off, 1:On)
        "REG66".toLowerCase(), //External Fan status (0:Off, 1:On)
        "REG67".toLowerCase(), //Compressor working status (0:Off, 1:On)
        "REG68".toLowerCase() //Electric heating working status (0:Off, 1:On)
      ]
    }
    else if (device.data.d_type.toLowerCase() == "evlite") {
      types = [
        "DI0".toLowerCase(), //Fire Alarm  0 = Normal   1 = Alarm
        "DI1".toLowerCase(), //Leak Alarm  0 = Dry   1 = Leakage
        "DI2".toLowerCase(), //BioMetric Acceptance   0 = Not in use  1 = Activated
        "DI3".toLowerCase(), //Front Door   0 = Open   1 = Closed
        "DI4".toLowerCase(), //Back Door  0 = Open   1 = Closed
        "REG20006".toLowerCase(), //Front Temperature Rack °C
        "REG20007".toLowerCase(), //Front Humidity Rack %
        "REG20008".toLowerCase(), //Back Temperature Rack °C
        "REG20009".toLowerCase(), //Back Humidity Rack %
        "REG20136".toLowerCase(), //Input Voltage Rack V
        "REG20138".toLowerCase(), //Active Power Rack W
        "REG20140".toLowerCase(), //Total Current  Rack A
        "REG20142".toLowerCase(), //Power Factor Rack
        "REG20144".toLowerCase(), //Input Voltage Frequency Hz
        "REG20146".toLowerCase() //Total Active Energy Rack Kwh
      ]
    }
    else if (device.data.d_type.toLowerCase() == "inverter") {
      /*
      Marked in doc + in original request
      Grid voltage
      AC output active power
      Output load percent
      Battery voltage
      Battery charging current
      Battery capacity
      PV Input current
      PV Input voltage
      Battery Discharge Current

      Device Status

      These were not marked on doc, but were in original request
      Grid frequency
      Grid watts
      AC Output voltage
      AC Output frequency
      BUS voltage
      Inverter heat sink temperature
      */
      types = [
        "ac_output_active_power".toLowerCase(),
        "ac_output_frequency".toLowerCase(),
        "ac_output_voltage".toLowerCase(),
        "battery_capacity".toLowerCase(),
        "battery_charging_current".toLowerCase(),
        "battery_voltage".toLowerCase(),
        "bus_voltage".toLowerCase(),
        "grid_frequency".toLowerCase(),
        "grid_voltage".toLowerCase(),
        "inverter_heat_sink_temperature".toLowerCase(),
        "inverter_mode".toLowerCase(),
        "output_load_energy".toLowerCase(),
        "output_load_percent".toLowerCase(),
        "pv_input_current".toLowerCase(),
        "pv_input_voltage".toLowerCase(),
        "battery_type".toLowerCase(),
        "output_source_priority".toLowerCase(),
        "battery_charging_priority".toLowerCase(),
        "machine_type".toLowerCase(),
        "topology".toLowerCase(),
        "output_mode".toLowerCase()
      ]
    }
    else if (device.data.d_type.toLowerCase() == "line counter") {
      types = [
        "in".toLowerCase(),
        "out".toLowerCase()
      ]
    }
    else if (device.data.d_type.toLowerCase() == "generator") {
      types = [
        "ai0", "reg20002", "fuel level",  //Fuel level 
        "reg20000", 
        "reg20001", "coolant temp",
        "reg20003", "plant battery", //Battery
        "reg20018",
        "reg20020",
        "reg20022",
        "reg20004", "engine speed",
        "reg20006",
        "reg20008",
        "reg20010",
        "reg20012",
        "reg20014",
        "reg20016",
        "reg20023", "generator mode",
        "reg20025",
        "reg20027",
        "extpwr",
        "load l1", "load l2", "load l3",
        "fuel temp",
        "oil temp",
        "energy",
        "reg20005",
        "reg20009",
        "reg20011",
        "reg20013",
        "reg20128",
        "reg20130",
        "reg20132",
        "reg20134",
        "reg20019",
        "reg20017",
        "reg20021",
        "reg20024",
        "reg20007",
        "reg20015",
        "reg20034"
      ]
    }
    else if (device.data.d_type.toLowerCase() == "llcdatacenter") {
      types = [
        "3rdParty-Pod-ExtTemp1",
        "3rdParty-Pod-IntTemp1", 
        "3rdParty-Pod-IntTemp3",
        "Data Center Humidity",
        "IT_Chiller_Pump1",
        "IT_Chiller_Pump2",
        "IT_Chiller_Pump3",
        "IT_Chiller_Tank_Level",
        "Liberty-OHS-Test3",
        "Network-Pod-ExtTemp2",
        "Network-Pod-ExtTemp3",
        "Network-Pod-ExtTemp4",
        "Network-Pod-IntTemp1",
        "Network-Pod-IntTemp2",
        "Network-Pod-IntTemp3",
        "Network-Pod-IntTemp4",
        "Server-Pod1-ExtTemp1",
        "Server-Pod1-ExtTemp2",
        "Server-Pod1-IntTemp1",
        "Water_Return_Temp",
        "Water_Supply_Temp",
        "600KL",
        "600KL Water Tank",
        "HeartBeat",
        "Grey_Water_Tank",
        "Domestic_Water_Tank",
        "300KL_Water_Tank",
        "Building_Chiller_Tank"
      ]
    }
    else if (device.data.d_type.toLowerCase() == "fridge") {
      types = [
        "humidity",
        "temperature",
        "battery"
      ]
    }
    else if (device.data.d_type.toLowerCase() == "tank") {
      types = [
        "600KL",
        "600KL Water Tank",
        "Grey_Water_Tank",
        "Domestic_Water_Tank",
        "300KL_Water_Tank",
        "Building_Chiller_Tank"
      ]
    }
    else if (device.data.d_type.toLowerCase() == "LoRaWAN Controller".toLowerCase()) {
      types = [
        "power_status".toLowerCase(),
        "Temperature1".toLowerCase(),
        "Temperature2".toLowerCase(),
        "CT1_Current_status".toLowerCase(),
        "CT2_Current_status".toLowerCase()
      ]
    }
    else if (device.data.d_type.toLowerCase() == "LoRaWAN TempRH".toLowerCase()) {
      types = [
        "temperature".toLowerCase(),
        "humidity".toLowerCase(),
        "leakage_status".toLowerCase(),
        "battery".toLowerCase()
      ]
    }
    else if (device.data.d_type.toLowerCase() == "Pressure".toLowerCase()) {
      types = [
        "value"
      ]
    }

    return types.filter(x=>x.toString().toLowerCase().trim() == compare.toString().toLowerCase().trim()).length > 0;
  }

  processSpikeDetection(data) {
    var url = `https://ml.travelfind.me/api/ml`;

    var input = {
      type: 0,
      confidence: 95,
      historyLength: data.length,
      data:data
    };

    return this.http.post(url, input).toPromise().then((response:any) => {
      return response;
    }).catch(this.error);
  }
  processChangeDetection(data) {
    var url = `https://ml.travelfind.me/api/ml`;

    var input = {
      type: 1,
      confidence: 95,
      historyLength: data.length,
      data:data
    };

    return this.http.post(url, input).toPromise().then((response:any) => {
      return response;
    }).catch(this.error);
  }

  getPointParms(pointName, version = 1, type = 'Room') : any {    
    if (version == 1) {

      if (type && type.toLowerCase() == "pressure") {
        switch (pointName.toString().toLowerCase().trim())
        {
          case "value".toLowerCase():
            return { name:pointName, class:'pressure', display:"Water Pressure", displayShort:"Pressure", image:"Warm.svg", symbol:"Kpa", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
        }
        
      }
      else if (type && type.toLowerCase() == "inverter") {
        switch (pointName.toString().toLowerCase().trim())
        {
          /*
          "ac_output_active_power".toLowerCase(),
          "ac_output_frequency".toLowerCase(),
          "ac_output_voltage".toLowerCase(),
          
          "battery_charging_current".toLowerCase(),
          
          "bus_voltage".toLowerCase(),
          "grid_frequency".toLowerCase(),
          
          "inverter_heat_sink_temperature".toLowerCase(),
          "inverter_mode".toLowerCase(),
          "output_load_energy".toLowerCase(),
          
          "pv_input_current".toLowerCase(),
          */
          /*
            -Grid voltage
            AC output active power
            -Output load percent
            -Battery voltage
            Battery charging current
            -Battery capacity
            PV Input current
            -PV Input voltage
            Battery Discharge Current
          */
          case "grid_voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Grid Voltage", displayShort:"Grid", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "battery_voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Battery Voltage", displayShort:"Battery", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "output_load_percent".toLowerCase():
            return { name:pointName, class:'percentage', display:"Output Load", displayShort:"Load", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "ac_output_active_power".toLowerCase():
            return { name:pointName, class:'watts', display:"Output Load", displayShort:"Load", image:"Warm.svg", symbol:"W", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "output_load_energy".toLowerCase():
            return { name:pointName, class:'kilowatts', display:"Energy Utilisation", displayShort:"Energy", image:"Warm.svg", symbol:"kWh", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1000).toFixed(2);} };
          case "battery_capacity".toLowerCase():
            return { name:pointName, class:'percentage', display:"Battery Capacity", displayShort:"Capacity", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'battery', format:function(v) { return (v/1).toFixed(2);} };
          case "pv_input_voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"PV Input voltage", displayShort:"PV Input", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "ac_output_voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Output Voltage", displayShort:"AC Output", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
            

          case "inverter_mode":
            return { name:pointName, class:'mode', display:"Inverter Mode", displayShort:"Mode", image:"Inverter Mode.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:9, showAs:'text'  };
          case "inverter_heat_sink_temperature":
            return { name:pointName, class:'temperature', display:"Heat Sink Temperature", displayShort:"Heat Sink", image:"Heat Sink Temp.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:5, showAs:'gauge'  };            
          case "battery_type":
            return { name:pointName, class:'battery', display:"Battery Type", displayShort:"Battery", image:"Low Battery.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:9, showAs:'text'  };
          case "output_source_priority":
            return { name:pointName, class:'source', display:"Output Source Priority", displayShort:"Output Source", image:"Output Source Priority.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:9, showAs:'text'  };
          case "battery_charging_priority":
            return { name:pointName, class:'source', display:"Battery Charging Priority", displayShort:"Battery Charge", image:"Charge Priority.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:9, showAs:'text'  };
          case "machine_type":
            return { name:pointName, class:'machine', display:"Machine Type", displayShort:"Machine", image:"Machine Type.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:9, showAs:'text'  };
          case "topology":
            return { name:pointName, class:'topology', display:"Topology", displayShort:"Topology", image:"Topology.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:9, showAs:'text'  };
          case "output_mode":
            return { name:pointName, class:'output_mode', display:"Output Mode", displayShort:"Output", image:"Output Mode.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:9, showAs:'text'  };
          
          // case "Frequency".toLowerCase():
          //   return { name:pointName, class:'hertz', display:"Frequency", displayShort:"Frequency", image:"Warm.svg", symbol:"Hz", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:9, showAs:'gauge', format:function(v) { return (v*0.1).toFixed(2);} };
          // case "UPS Battery Status".toLowerCase():
          //   return { name:pointName, class:'ups_status', display:"UPS Battery Status", displayShort:"Status", image:"Electricity Supply-solid.png", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:3,max:4,text:'Alert',status:'danger'}, {min:2,max:2,text:'Normal',status:'success'}], markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:7, showAs:'input', format:function(v) { return (v/1).toFixed(2);} };
          // case "UPS Output Source".toLowerCase():
          //   return { name:pointName, class:'ups_source', display:"UPS Output Source", displayShort:"Max Demand", image:"Jacks-solid.png", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:4,max:7,text:'Alert',status:'danger'}, {min:3,max:3,text:'Normal',status:'success'}], markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:8, showAs:'input', format:function(v) { return (v/1).toFixed(2);} };
          // case "Time on Battery".toLowerCase():
          //   return { name:pointName, class:'time', display:"Time on Battery", displayShort:"Time on Battery", image:"Warm.svg", symbol:"seconds", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:6, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };

          default:
            return null;
        }
      }
      else if (type && type.toLowerCase() == "LoRaWAN Controller".toLowerCase()) {
        switch (pointName.toString().toLowerCase().trim())
        {
          case "power_status".toLowerCase():
            return { name:pointName, class:'power', display:"Power Status", displayShort:"Power", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:'on',max:'on',text:'On',status:'success'}, {min:'off',max:'off',text:'Off',status:'danger'}], data:{}, sortOrder:5, showAs:'input'  };
          case "Temperature1".toLowerCase():
            return { name:pointName, class:'temperature', display:"Temperature 1", displayShort:"Temp 1", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Temperature2".toLowerCase():
            return { name:pointName, class:'temperature', display:"Temperature 2", displayShort:"Temp 2", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "CT1_Current_status".toLowerCase():
            return { name:pointName, class:'source', display:"CT1 Status", displayShort:"CT1 Status", image:"Output Mode.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'No Current',status:'info'}, {min:1,max:1,text:'AC Current',status:'info'}], data:{}, sortOrder:3, showAs:'input'  };
          case "CT2_Current_status".toLowerCase():
            return { name:pointName, class:'source', display:"CT2 Status", displayShort:"CT2 Status", image:"Output Mode.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'No Current',status:'info'}, {min:1,max:1,text:'AC Current',status:'info'}], data:{}, sortOrder:4, showAs:'input'  };

          default:
            return null;
        }
          
      }
      else if (type && type.toLowerCase() == "LoRaWAN TempRH".toLowerCase()) {
        switch (pointName.toString().toLowerCase().trim())
        {
          case "temperature".toLowerCase():
            return { name:pointName, class:'temperature', display:"Temperature", displayShort:"Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "humidity".toLowerCase():
            return { name:pointName, class:'humidity', display:"Humidity", displayShort:"Humi", image:"Humidity.svg", symbol:"%H", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "leakage_status".toLowerCase():
            return { name:pointName, class:'source', display:"Leakage Status", displayShort:"Leakage", image:"Raindrop.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Normal',status:'success'}, {min:1,max:1,text:'Problem',status:'danger'}], data:{}, sortOrder:3, showAs:'input'  };
          case "battery".toLowerCase():
            return { name:pointName, class:'percentage', display:"Battery Capacity", displayShort:"Capacity", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'battery', format:function(v) { return (v/1).toFixed(2);} };

          default:
            return null;
        }
          
      }
      else if (type && type.toLowerCase() == "evrack") {
        switch (pointName.toString().toLowerCase().trim())
        {
          //Energy meter
          //case "REG20128".toLowerCase():
          case "Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Voltage", displayShort:"Voltage", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          //case "REG20138".toLowerCase():
          case "TotalActiveEnergy".toLowerCase():
            return { name:pointName, class:'kilowatthour', display:"Total Energy", displayShort:"Energy", image:"Warm.svg", symbol:"kWh", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          //case "REG20130".toLowerCase():
          case "Current".toLowerCase():
            return { name:pointName, class:'amps', display:"Current", displayShort:"Current", image:"Warm.svg", symbol:"A", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          //case "REG20134".toLowerCase():
          case "PowerFactor".toLowerCase():
            return { name:pointName, class:'factor', display:"Power Factor", displayShort:"Factor", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:7, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };
          //case "REG20136".toLowerCase():
          case "Frequency".toLowerCase():
            return { name:pointName, class:'hertz', display:"Frequency", displayShort:"Frequency", image:"Warm.svg", symbol:"Hz", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:6, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          //case "REG20132".toLowerCase():
          case "ActivePower".toLowerCase():
            return { name:pointName, class:'watts', display:"Active Power", displayShort:"Power", image:"Warm.svg", symbol:"W", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          // case "REG20140".toLowerCase():
          //   return { name:pointName, class:'watts', display:"Max Demand Power", displayShort:"Max Demand", image:"Warm.svg", symbol:"W", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          
          //Rack / AC
          case "REG20000".toLowerCase():
            return { name:pointName, class:'temperature', display:"Temperature", displayShort:"Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:8, showAs:'gauge' };
          case "DI0".toLowerCase():
            return { name:pointName, class:'door', display:"EV Rack Door", displayShort:"Door", image:"Door.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Closed',status:'success'}, {min:1,max:1,text:'Open',status:'danger'}], data:{}, sortOrder:9, showAs:'input'  };
          case "REG64".toLowerCase():
            return { name:pointName, class:'source', display:"AC Working status", displayShort:"AC Working", image:"Output Mode.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'success'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:10, showAs:'input'  };
          case "REG65".toLowerCase():
            return { name:pointName, class:'source', display:"AC Internal Fan status", displayShort:"AC Internal Fan", image:"Fan.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'success'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:11, showAs:'input'  };
          case "REG66".toLowerCase():
            return { name:pointName, class:'source', display:"AC External Fan status", displayShort:"AC EXternal Fan", image:"Fan.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'success'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:12, showAs:'input' };
          case "REG67".toLowerCase():
            return { name:pointName, class:'source', display:"AC Compressor status", displayShort:"AC Compressor", image:"Control.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'success'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:13, showAs:'input'  };
          case "REG68".toLowerCase():
            return { name:pointName, class:'source', display:"AC Electric heating status", displayShort:"AC Heating", image:"Heat Sink Temp.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'success'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:14, showAs:'input'  };

          default:
            return null;
        }
      }
      else if (type && type.toLowerCase() == "evlite") {
        switch (pointName.toString().toLowerCase().trim())
        {
          //Rack
          case "REG20006".toLowerCase():
            return { name:pointName, class:'temperature', display:"Front Temperature", displayShort:"Front Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge' };
          case "REG20008".toLowerCase():
            return { name:pointName, class:'temperature', display:"Back Temperature", displayShort:"Back Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'gauge' };
          case "REG20007":
            return { name:pointName, class:'humidity', display:"Front Humidity", displayShort:"Front Humi", image:"Humidity.svg", symbol:"%H", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3 };
          case "REG20009":
            return { name:pointName, class:'humidity', display:"Back Humidity", displayShort:"Back Humi", image:"Humidity.svg", symbol:"%H", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4 };            
          case "REG20136".toLowerCase():
            return { name:pointName, class:'voltage', display:"Input Voltage", displayShort:"Voltage", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20138".toLowerCase():
            return { name:pointName, class:'watts', display:"Active Power", displayShort:"Power", image:"Warm.svg", symbol:"W", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:6, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20140".toLowerCase():
            return { name:pointName, class:'amps', display:"Total Current", displayShort:"Current", image:"Warm.svg", symbol:"A", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:7, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20142".toLowerCase():
            return { name:pointName, class:'factor', display:"Power Factor", displayShort:"Factor", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:8, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20144".toLowerCase():
            return { name:pointName, class:'hertz', display:"Input Voltage Frequency", displayShort:"Frequency", image:"Warm.svg", symbol:"Hz", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:9, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20146".toLowerCase():
            return { name:pointName, class:'kilowatts', display:"Total Active Energy", displayShort:"Energy", image:"Warm.svg", symbol:"kWh", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:10, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };
              
          case "DI0".toLowerCase():
            return { name:pointName, class:'door', display:"Fire Alarm", displayShort:"Fire", image:"Door.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Normal',status:'success'}, {min:1,max:1,text:'Alarm',status:'danger'}], data:{}, sortOrder:11, showAs:'input'  };
          case "DI1".toLowerCase():
            return { name:pointName, class:'door', display:"Leak Alarm", displayShort:"Leak", image:"Door.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Dry',status:'success'}, {min:1,max:1,text:'Leakage',status:'danger'}], data:{}, sortOrder:12, showAs:'input'  };
          case "DI2".toLowerCase():
            return { name:pointName, class:'door', display:"BioMetric Acceptance", displayShort:"BioMetric", image:"Door.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Not in use',status:'danger'}, {min:1,max:1,text:'Activated',status:'success'}], data:{}, sortOrder:13, showAs:'input'  };
          case "DI3".toLowerCase():
            return { name:pointName, class:'door', display:"Front Door", displayShort:"Front Door", image:"Door.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Open',status:'danger'}, {min:1,max:1,text:'Closed',status:'success'}], data:{}, sortOrder:14, showAs:'input'  };
          case "DI4".toLowerCase():
            return { name:pointName, class:'door', display:"Back Door", displayShort:"Back Door", image:"Door.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Open',status:'danger'}, {min:1,max:1,text:'Closed',status:'success'}], data:{}, sortOrder:15, showAs:'input'  };
          
          default:
            return null;
        }
      }
      else if (type && type.toLowerCase() == "water meter") {
        switch (pointName.toString().toLowerCase().trim()) {
          case "WM_101_goldrush".toLowerCase():
            return { name:pointName, class:'factor', display:"101 Gold Rush Litres", displayShort:"101 Gold Rush", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_102_goldrush".toLowerCase():
            return { name:pointName, class:'factor', display:"102 Gold Rush Litres", displayShort:"102 Gold Rush", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_103_wits".toLowerCase():
            return { name:pointName, class:'factor', display:"103 Wits Litres", displayShort:"103 Wits", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_104_forest".toLowerCase():
            return { name:pointName, class:'factor', display:"104 Forest Litres", displayShort:"104 Forest", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_105_hustle".toLowerCase():
            return { name:pointName, class:'factor', display:"105 Hustle Litres", displayShort:"105 Hustle", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_106_hustle".toLowerCase():
            return { name:pointName, class:'factor', display:"106 Hustle Litres", displayShort:"106 Hustle", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_201_goldrush".toLowerCase():
            return { name:pointName, class:'factor', display:"201 Gold Rush Litres", displayShort:"201 Gold Rush", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_202_goldrush".toLowerCase():
            return { name:pointName, class:'factor', display:"202 Gold Rush Litres", displayShort:"202 Gold Rush", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_203_wits".toLowerCase():
            return { name:pointName, class:'factor', display:"203 Wits Litres", displayShort:"203 Wits", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_204_forest".toLowerCase():
            return { name:pointName, class:'factor', display:"204 Forest Litres", displayShort:"204 Forest", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_205_hustle".toLowerCase():
            return { name:pointName, class:'factor', display:"205 Hustle Litres", displayShort:"205 Hustle", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_206_hustle".toLowerCase():
            return { name:pointName, class:'factor', display:"206 Hustle Litres", displayShort:"206 Hustle", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:6, showAs:'number', format:function(v) { return (v/2).toFixed(0);} };
          case "WM_304_forest".toLowerCase():
            return { name:pointName, class:'factor', display:"304 Forest Litres", displayShort:"304 Forest", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/1).toFixed(0);} };
          case "WM_305_hustle".toLowerCase():
            return { name:pointName, class:'factor', display:"305 Hustle Litres", displayShort:"305 Hustle", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'number', format:function(v) { return (v/1).toFixed(0);} };
          case "WM_306_hustle".toLowerCase():
            return { name:pointName, class:'factor', display:"306 Hustle Litres", displayShort:"306 Hustle", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'number', format:function(v) { return (v/1).toFixed(0);} };
          case "WM_401_hustle".toLowerCase():
            return { name:pointName, class:'factor', display:"401 Hustle Litres", displayShort:"401 Hustle", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/1).toFixed(0);} };
          case "WM_402_hustle".toLowerCase():
            return { name:pointName, class:'factor', display:"402 Hustle Litres", displayShort:"402 Hustle", image:"Warm.svg", symbol:"Litres", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'number', format:function(v) { return (v/1).toFixed(0);} };
        }
      }
      else if (type && type.toLowerCase() == "water tank") {
        switch (pointName.toString().toLowerCase().trim()) {
          case "water_level".toLowerCase():
            return { name:pointName, class:'factor', display:"Level", displayShort:"Level", image:"Warm.svg", symbol:"Meters", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };
          case "percentage_value".toLowerCase():
            return { name:pointName, class:'percentage', display:"Percentage", displayShort:"Percentage", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'liquid', format:function(v) { return (v/1).toFixed(2);} };
        }
      }
      else if (type && type.toLowerCase() == "energy meter") {
        switch (pointName.toString().toLowerCase().trim()) {
          //Energy meter
          case "TotalActiveEnergy".toLowerCase():
            return { name:pointName, class:'kilowatts', display:"Total Active Energy", displayShort:"Energy", image:"Warm.svg", symbol:"kWh", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(3);} };
          case "Energy".toLowerCase():
            return { name:pointName, class:'kilowatthour', display:"Total Energy", displayShort:"Energy", image:"Warm.svg", symbol:"MWh", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/1000).toFixed(2);} };
          case "LineVoltageL1_2".toLowerCase():
            return { name:pointName, class:'voltage', display:"Line Voltage L1-2", displayShort:"Line Voltage L1-2", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "LineVoltageL2_3".toLowerCase():
            return { name:pointName, class:'voltage', display:"Line Voltage L2-3", displayShort:"Line Voltage L2-3", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "LineVoltageL3_1".toLowerCase():
            return { name:pointName, class:'voltage', display:"Line Voltage L3-1", displayShort:"Line Voltage L3-1", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Current1".toLowerCase():
          case "Phase1 Current".toLowerCase():
            return { name:pointName, class:'amps', display:"Current 1", displayShort:"Current 1", image:"Warm.svg", symbol:"A", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Current2".toLowerCase():
          case "Phase2 Current".toLowerCase():
            return { name:pointName, class:'amps', display:"Current 2", displayShort:"Current 2", image:"Warm.svg", symbol:"A", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:6, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Current3".toLowerCase():
          case "Phase2 Current".toLowerCase():
            return { name:pointName, class:'amps', display:"Current 3", displayShort:"Current 3", image:"Warm.svg", symbol:"A", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:7, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Frequency".toLowerCase():
            return { name:pointName, class:'hertz', display:"Frequency", displayShort:"Frequency", image:"Warm.svg", symbol:"Hz", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:8, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "ActivePower1".toLowerCase():
          case "Phase1 Active Power".toLowerCase():
            return { name:pointName, class:'watts', display:"Active Power 1", displayShort:"Power", image:"Warm.svg", symbol:"W", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:9, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "ActivePower2".toLowerCase():
          case "Phase2 Active Power".toLowerCase():
            return { name:pointName, class:'watts', display:"Active Power 2", displayShort:"Power", image:"Warm.svg", symbol:"W", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:10, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "ActivePower3".toLowerCase():
          case "Phase3 Active Power".toLowerCase():
            return { name:pointName, class:'watts', display:"Active Power 3", displayShort:"Power", image:"Warm.svg", symbol:"W", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Voltage1".toLowerCase():
          case "Phase1 Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Voltage 1", displayShort:"Voltage 1", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:12, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Voltage2".toLowerCase():
          case "Phase2 Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Voltage 2", displayShort:"Voltage 2", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:13, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Voltage3".toLowerCase():
          case "Phase3 Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Voltage 3", displayShort:"Voltage 3", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:14, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };  
          case "TotalPowerFactor".toLowerCase():
          case "System Power Factor".toLowerCase():
            return { name:pointName, class:'factor', display:"Power Factor", displayShort:"Factor", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:15, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };
          case "Total System Active Power(KA)".toLowerCase():
            return { name:pointName, class:'watts', display:"Total System Active Power", displayShort:"Power", image:"Warm.svg", symbol:"KA", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:16, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Total System Active Power(KW)".toLowerCase():
            return { name:pointName, class:'kilowatts', display:"Total System Active Power", displayShort:"Power", image:"Warm.svg", symbol:"kWh", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:17, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
        }
      }
      else if (type && type.toLowerCase() == "powerups") {
        switch (pointName.toString().toLowerCase().trim()) {
          case "UPS_Model_Name".toLowerCase():
            return { name:pointName, class:'model', display:"Model Name", displayShort:"Model", image:"", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:20, showAs:'text', format:function(v) { return v;} };
          case "UPS_Serial_Number".toLowerCase():
            return { name:pointName, class:'serial', display:"Serial Number", displayShort:"Serial", image:"", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:20, showAs:'text', format:function(v) { return v;} };
          case "UPS_Firmware_Version".toLowerCase():
            return { name:pointName, class:'firmware', display:"Firmware Version", displayShort:"Firmware", image:"", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:20, showAs:'text', format:function(v) { return v;} };
          case "UPS_Type".toLowerCase(): //0   Standy, 1   line-interactive, 2   Online
            return { name:pointName, class:'ups_type', display:"Type", displayShort:"Type", image:"Machine Type.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:0,text:'Alert',status:'danger'}, {min:1,max:2,text:'Normal',status:'success'}], markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:18, showAs:'input', format:function(v) { return v;} };
          case "UPS_Battery_Status".toLowerCase(): //1    Unknown, 2    Battery Normal, 3    Battery Low, 4    Battery Depleted, 5    Battery Discharging, 6    Battery Failure, 7    Battery Replace, 8    Battery silence        
            return { name:pointName, class:'ups_status', display:"Battery Status", displayShort:"Status", image:"Electricity Supply-solid.png", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:3,max:4,text:'Alert',status:'danger'}, {min:2,max:2,text:'Normal',status:'success'}], markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:17, showAs:'input', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS_Battery_Temperature".toLowerCase():
            return { name:pointName, class:'temperature', display:"Battery Temperature", displayShort:"Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Batteries_Quantity_Series".toLowerCase(): //None
            return { name:pointName, class:'batteries_quantity', display:"Batteries Quantity Series", displayShort:"Batteries Quantity", image:"Battery.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:18, showAs:'text', format:function(v) { return v;} };
          case "UPS_Battery_Capacity".toLowerCase():
            return { name:pointName, class:'percentage', display:"Battery Capacity", displayShort:"Capacity", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'battery', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS_Battery_Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Battery Voltage", displayShort:"Battery", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS_Input_Frequency".toLowerCase():
            return { name:pointName, class:'hertz', display:"Frequency", displayShort:"Frequency", image:"Warm.svg", symbol:"Hz", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:7, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          case "UPS_Input_Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Input Voltage", displayShort:"Input", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:8, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS_Output_Source".toLowerCase(): //1    other, 2    none, 3    normal, 4    bypass, 5    battery, 6    booster, 7    reducer, 8    battery test, 9    fault, 10   HE-ECOmode, 11   convertermode
            return { name:pointName, class:'ups_source', display:"UPS Output Source", displayShort:"Output Source", image:"Jacks-solid.png", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:4,max:7,text:'Alert',status:'danger'}, {min:3,max:3,text:'Normal',status:'success'}], markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:18, showAs:'input', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS_Load_Apparent_Power".toLowerCase(): //kVA
            return { name:pointName, class:'kilovoltamps', display:"Load Apparent Power", displayShort:"Apparent Power", image:"Warm.svg", symbol:"kVA", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:10, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS_Load_Active_Power".toLowerCase():
              return { name:pointName, class:'kilowatts', display:"Load Active Power", displayShort:"Active Power", image:"Warm.svg", symbol:"kW", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS_Output_Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Output Voltage", displayShort:"Output", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:12, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS_Output_Current".toLowerCase():
              return { name:pointName, class:'amps', display:"Output Current", displayShort:"Current", image:"Warm.svg", symbol:"A", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:13, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS_Output_Percent_Load".toLowerCase():
            return { name:pointName, class:'percentage', display:"Output Load", displayShort:"Load", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:14, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };          
          case "UPS_Bypass_Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Bypass Voltage", displayShort:"Bypass", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:15, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };              
          case "UPS_Bypass_Current".toLowerCase():
            return { name:pointName, class:'amps', display:"Bypass Current", displayShort:"Current", image:"Warm.svg", symbol:"A", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:16, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          }
      }
      else {
        switch (pointName.toString().toLowerCase().trim())
        {
          case "heartbeat":
            return { name:pointName, class:'heartbeat', display:"Heart Beat", displayShort:"Heart Beat", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'time', format:function(v) { return v;} };
          
          case "battery":
            return { name:pointName, class:'battery', display:"Battery Status", displayShort:"Battery", image:"Power.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:3, showAs:'battery'  };
          
          case "temp":
          case "temperature":
            return { name:pointName, class:'temperature', display:"Temperature", displayShort:"Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1 };
          case "humi":
          case "humidity":
            return { name:pointName, class:'humidity', display:"Humidity", displayShort:"Humi", image:"Humidity.svg", symbol:"%H", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2 };
          case "extpwr":
            return { name:pointName, class:'mains', display:"Mains Power Status", displayShort:"Mains", image:"Power.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:3  };
          // case "di0":
          //   return { display:"Mains Power Status", image:"Power.svg", states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}]  };

          case "di1":
            return { name:pointName, class:'door', display:"Door", displayShort:"Door", image:"Door.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Open',status:'danger'}, {min:1,max:1,text:'Closed',status:'success'}], data:{}, sortOrder:4  };
          case "di2":
            return { name:pointName, class:'leak', display:"Leak Sensor", displayShort:"Leak", image:"Raindrop.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Wet',status:'danger'}, {min:1,max:1,text:'Dry',status:'success'}], data:{}, sortOrder:5  };
          case "di3":
            return { name:pointName, class:'smoke', display:"Smoke Sensor", displayShort:"Smoke", image:"Fire.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Fire',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:6  };
          case "di4":
            return { name:pointName, class:'over_voltage', display:"Over Voltage", displayShort:"OVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:7  };
          case "di5":
            return { name:pointName, class:'under_voltage', display:"Under Voltage", displayShort:"UVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1, text:'Normal',status:'success'}], data:{}, sortOrder:8 };
          case "di6":
            return { name:pointName, class:'pdu_power', display:"PDU Power", displayShort:"PDU", image:"Jacks.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:9  };
          case "di7":
            return { name:pointName, class:'ups_power', display:"UPS Power", displayShort:"UPS", image:"Low Battery.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:10  };
          
          case "co2":
            return { name:pointName, class:'co2', display:"Carbon Dioxide", displayShort:"CO2", image:"", symbol:"ppm", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11 };
          case "tvoc":
            return { name:pointName, class:'tvoc', display:"Total Volatile Organic Compounds", displayShort:"TVOC", image:"", symbol:"ppb", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11 };
          case "illumination":
            return { name:pointName, class:'illumination', display:"Illumination", displayShort:"Illumination", image:"", symbol:"lumen", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11 };
          case "light_level":
            return { name:pointName, class:'illumination', display:"Light Level", displayShort:"Light Level", image:"", symbol:"lumen", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11 };
          case "pressure":
            return { name:pointName, class:'pressure', display:"Pressure", displayShort:"Pressure", image:"", symbol:"hPA", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11 };
          
          //Generator
          case "reg20000":
            return { name:pointName, class:'oil_pressure', display:"Oil Pressure", displayShort:"Pressure", image:"Warm.svg", symbol:"PSI", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/10).toFixed(2);} };
          case "reg20002":
          case "fuel level":
            return { name:pointName, class:'fuel_level', display:"Fuel Level", displayShort:"Fuel", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return v;} };
          case "ai0":
            return { name:pointName, class:'fuel_level_2', display:"Fuel Level", displayShort:"Fuel", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (100 - (((v-8)/8)*100)).toFixed(2);} };
          case "reg20003":
            return { name:pointName, class:'battery', display:"Battery Voltage", displayShort:"Battery", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/10).toFixed(2);}  };
          case "reg20003_1":
            return { name:pointName, class:'battery', display:"Battery Voltage", displayShort:"Battery", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);}  };
          case "plant battery":
              return { name:pointName, class:'battery', display:"Battery Voltage", displayShort:"Battery", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return v;}  };
          
          case "reg20018":
          case "load l1":
            //return { name:pointName, class:'load_a', display:"Load A", displayShort:"L1", image:"Power.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:1, showAs:'input'  };
            return { name:pointName, class:'load_a', display:"Load A", displayShort:"L1", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          case "reg20020":
          case "load l2":
            //return { name:pointName, class:'load_b', display:"Load B", displayShort:"L2", image:"Power.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:2, showAs:'input'  };
            return { name:pointName, class:'load_b', display:"Load B", displayShort:"L2", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          case "reg20022":
          case "load l3":
            //return { name:pointName, class:'load_c', display:"Load C", displayShort:"L3", image:"Power.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:3, showAs:'input'  };
            return { name:pointName, class:'load_c', display:"Load C", displayShort:"L3", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          
          case "reg20004":
          case "engine speed":
            return { name:pointName, class:'engine_speed', display:"Engine Speed", displayShort:"Speed", image:"Power.svg", symbol:"RPM", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v > 65500 ? 0 : v);} };

          case "reg20006":
            return { name:pointName, class:'output_voltage_a', display:"Output Voltage A", displayShort:"Output A", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/10).toFixed(2);} };
          case "reg20008":
            return { name:pointName, class:'output_voltage_b', display:"Output Voltage B", displayShort:"Output B", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/10).toFixed(2);} };
          case "reg20010":
            return { name:pointName, class:'output_voltage_c', display:"Output Voltage C", displayShort:"Output C", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/10).toFixed(2);} };
        
          case "outputvoltage l1":
            return { name:pointName, class:'output_voltage_a', display:"Output Voltage A", displayShort:"Output A", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "outputvoltage l2":
            return { name:pointName, class:'output_voltage_b', display:"Output Voltage B", displayShort:"Output B", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "outputvoltage l3":
            return { name:pointName, class:'output_voltage_c', display:"Output Voltage C", displayShort:"Output C", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
            

          case "reg20012":
            return { name:pointName, class:'mains_voltage_a', display:"Input Voltage A", displayShort:"Input A", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/10).toFixed(2);} };
          case "reg20014":
            return { name:pointName, class:'mains_voltage_b', display:"Input Voltage B", displayShort:"Input B", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/10).toFixed(2);} };
          case "reg20016":
            return { name:pointName, class:'mains_voltage_c', display:"Input Voltage C", displayShort:"Input C", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/10).toFixed(2);} };
          case "reg20023":
          case "generator mode":
            return { name:pointName, class:'mode', display:"Generator Mode", displayShort:"Mode", image:"Power.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:3, showAs:'input'  };
          case "reg20001":
          case "coolant temp":
            return { name:pointName, class:'coolant_temp', display:"Coolant Temperature", displayShort:"Coolant", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v > 32760 ? 0 : v);} };
          case "fuel temp":
            return { name:pointName, class:'fuel_temp', display:"Fuel Temperature", displayShort:"Fuel Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v > 32760 ? 0 : v);} };
          case "oil temp":
            return { name:pointName, class:'oil_temp', display:"Oil Temperature", displayShort:"Oil Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v > 32760 ? 0 : v);} };
          case "energy":
            return { name:pointName, class:'energy', display:"Total Hours", displayShort:"Hours", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'number', format:function(v) { return (v/60/60).toFixed(2);} };
          
          //case "reg20025":
          case "reg20128":
            return { name:pointName, class:'run_time', display:"Run Time", displayShort:"Run Time", image:"Warm.svg", symbol:"mins", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/60).toFixed(2);} };
          // case "reg20027":
          //   return { name:pointName, display:"Fuel Used", displayShort:"Fuel Usage", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v);} };
        
          //Datacenter
          case "3rdParty-Pod-ExtTemp1".toLowerCase():
            return { name:pointName, class:'pod_external_temp', display:"Pod External Temp", displayShort:"Pod Ext. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v).toFixed(2);} };
          case "3rdParty-Pod-IntTemp1".toLowerCase():
            return { name:pointName, class:'pod_external_temp', display:"Pod Internal Temp 1", displayShort:"Pod Ext. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v).toFixed(2);} };
          case "3rdParty-Pod-IntTemp3".toLowerCase():
            return { name:pointName, class:'pod_external_temp', display:"Pod Internal Temp 3", displayShort:"Pod Ext. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v).toFixed(2);} };
          case "Data Center Humidity".toLowerCase():
            return { name:pointName, class:'humidity', display:"Data Center Humidity", displayShort:"Humidity", image:"Humidity.svg", symbol:"%H", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'humidity', format:function(v) { return (v).toFixed(2);} };
          case "IT_Chiller_Pump1".toLowerCase():
            return { name:pointName, class:'pump', display:"Chiller Pump 1", displayShort:"Pump 1", image:"water-pump.png?v=1", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:0,text:'Alert',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:12, showAs:'input'  };
          case "IT_Chiller_Pump2".toLowerCase():
            return { name:pointName, class:'pump', display:"Chiller Pump 2", displayShort:"Pump 2", image:"water-pump.png?v=1", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:0,text:'Alert',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:13, showAs:'input'  };
          case "IT_Chiller_Pump3".toLowerCase():
            return { name:pointName, class:'pump', display:"Chiller Pump 3", displayShort:"Pump 3", image:"water-pump.png?v=1", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:0,text:'Alert',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:14, showAs:'input'  };
          case "IT_Chiller_Tank_Level".toLowerCase():
            return { name:pointName, class:'tank_level', display:"Chiller Tank Level", displayShort:"Tank Level", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'liquid', format:function(v) { return (v).toFixed(2);} };
          case "Network-Pod-ExtTemp2".toLowerCase():
            return { name:pointName, class:'temperature', display:"Network Pod External Temp 2", displayShort:"Pod Ext. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:9, showAs:'temperature', format:function(v) { return (v).toFixed(2);} };
          case "Network-Pod-ExtTemp3".toLowerCase():
            return { name:pointName, class:'temperature', display:"Network Pod External Temp 3", displayShort:"Pod Ext. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:10, showAs:'temperature', format:function(v) { return (v).toFixed(2);} };
          case "Network-Pod-ExtTemp4".toLowerCase():
            return { name:pointName, class:'temperature', display:"Network Pod External Temp 4", displayShort:"Pod Ext. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11, showAs:'temperature', format:function(v) { return (v).toFixed(2);} };
          case "Network-Pod-IntTemp1".toLowerCase():
            return { name:pointName, class:'temperature', display:"Network Pod Internal Temp 1", displayShort:"Pod Int. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'temperature', format:function(v) { return (v).toFixed(2);} };
          case "Network-Pod-IntTemp2".toLowerCase():
            return { name:pointName, class:'temperature', display:"Network Pod Internal Temp 2", displayShort:"Pod Int. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:6, showAs:'temperature', format:function(v) { return (v).toFixed(2);} };
          case "Network-Pod-IntTemp3".toLowerCase():
            return { name:pointName, class:'temperature', display:"Network Pod Internal Temp 3", displayShort:"Pod Int. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:7, showAs:'temperature', format:function(v) { return (v).toFixed(2);} };
          case "Network-Pod-IntTemp4".toLowerCase():
            return { name:pointName, class:'temperature', display:"Network Pod Internal Temp 4", displayShort:"Pod Int. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:8, showAs:'temperature', format:function(v) { return (v).toFixed(2);} };
          case "Server-Pod1-ExtTemp1".toLowerCase():
            return { name:pointName, class:'pod_external_temp', display:"Server Pod External Temp 1", displayShort:"Pod Ext. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v).toFixed(2);} };
          case "Server-Pod1-ExtTemp2".toLowerCase():
            return { name:pointName, class:'pod_external_temp', display:"Server Pod External Temp 2", displayShort:"Pod Ext. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v).toFixed(2);} };
          case "Server-Pod1-IntTemp1".toLowerCase():
            return { name:pointName, class:'pod_external_temp', display:"Server Pod Internal Temp 1", displayShort:"Pod Ext. Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v).toFixed(2);} };
          case "Water_Return_Temp".toLowerCase():
            return { name:pointName, class:'temperature', display:"Water Return Temp", displayShort:"Return Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'temperature', classes:'flow', format:function(v) { return (v).toFixed(2);} };
          case "Water_Supply_Temp".toLowerCase():
            return { name:pointName, class:'temperature', display:"Water Supply Temp", displayShort:"Supply Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'temperature', classes:'flow', format:function(v) { return (v).toFixed(2);} };
          case "600KL".toLowerCase():
          case "600KL Water Tank".toLowerCase():
              return { name:pointName, class:'tank_level', display:"600KL Tank", displayShort:"600KL Level", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'liquid', format:function(v) { return (v).toFixed(2);} };
          case "Grey_Water_Tank".toLowerCase():
            return { name:pointName, class:'tank_level', display:"Grey Water Tank", displayShort:"Grey Water Level", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'liquid', format:function(v) { return (v).toFixed(2);} };
          case "Domestic_Water_Tank".toLowerCase():
            return { name:pointName, class:'tank_level', display:"Domestic Water Tank", displayShort:"Domestic Water Level", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'liquid', format:function(v) { return (v).toFixed(2);} };
          case "300KL_Water_Tank".toLowerCase():
            return { name:pointName, class:'tank_level', display:"300KL Tank", displayShort:"300KL Level", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'liquid', format:function(v) { return (v).toFixed(2);} };
          case "Building_Chiller_Tank".toLowerCase():
            return { name:pointName, class:'tank_level', display:"Building Chiller Tank", displayShort:"Building Chiller Level", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'liquid', format:function(v) { return (v).toFixed(2);} };
          
          //Single Phase Energy meter
          case "voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Voltage", displayShort:"Voltage", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "TotalEnergy".toLowerCase():
            return { name:pointName, class:'kilowatthour', display:"Total Energy", displayShort:"Energy", image:"Warm.svg", symbol:"kWh", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Current".toLowerCase():
            return { name:pointName, class:'amps', display:"Current", displayShort:"Current", image:"Warm.svg", symbol:"A", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "PowerFactor".toLowerCase():
            return { name:pointName, class:'factor', display:"Power Factor", displayShort:"Factor", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:7, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };
          case "Frequency".toLowerCase():
            return { name:pointName, class:'hertz', display:"Frequency", displayShort:"Frequency", image:"Warm.svg", symbol:"Hz", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:6, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "ActivePower".toLowerCase():
            return { name:pointName, class:'watts', display:"Active Power", displayShort:"Power", image:"Warm.svg", symbol:"W", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "MaxDemandPower".toLowerCase():
            return { name:pointName, class:'watts', display:"Max Demand Power", displayShort:"Max Demand", image:"Warm.svg", symbol:"W", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };

          //UPS
          case "Input Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Input Voltage", displayShort:"Input", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Ouput Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Output Voltage", displayShort:"Output", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Output Load".toLowerCase():
            return { name:pointName, class:'percentage', display:"Output Load", displayShort:"Load", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Battery Voltage".toLowerCase():
            return { name:pointName, class:'voltage', display:"Battery Voltage", displayShort:"Battery", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "Battery Capacity".toLowerCase():
            return { name:pointName, class:'percentage', display:"Battery Capacity", displayShort:"Capacity", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'battery', format:function(v) { return (v/1).toFixed(2);} };
          case "Frequency".toLowerCase():
            return { name:pointName, class:'hertz', display:"Frequency", displayShort:"Frequency", image:"Warm.svg", symbol:"Hz", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:9, showAs:'gauge', format:function(v) { return (v*0.1).toFixed(2);} };
          case "UPS Battery Status".toLowerCase():
            return { name:pointName, class:'ups_status', display:"UPS Battery Status", displayShort:"Status", image:"Electricity Supply-solid.png", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:3,max:4,text:'Alert',status:'danger'}, {min:2,max:2,text:'Normal',status:'success'}], markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:7, showAs:'input', format:function(v) { return (v/1).toFixed(2);} };
          case "UPS Output Source".toLowerCase():
            return { name:pointName, class:'ups_source', display:"UPS Output Source", displayShort:"Max Demand", image:"Jacks-solid.png", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:4,max:7,text:'Alert',status:'danger'}, {min:3,max:3,text:'Normal',status:'success'}], markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:8, showAs:'input', format:function(v) { return (v/1).toFixed(2);} };
          case "Time on Battery".toLowerCase():
            return { name:pointName, class:'time', display:"Time on Battery", displayShort:"Time on Battery", image:"Warm.svg", symbol:"seconds", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:6, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };
          
          //Line counter
          case "in".toLowerCase():
            return { name:pointName, class:'count', display:"People In", displayShort:"In", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };
          case "out".toLowerCase():
            return { name:pointName, class:'count', display:"People Out", displayShort:"Out", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };

          default:
            return null;
        }
      }
    }
    else if (version == 2) {
      if (type && type.toLowerCase() == "generator") {
        switch (pointName.toString().toLowerCase()) {
          //Generator
          case "reg20000".toLowerCase():
            return { name:pointName, class:'oil_pressure', display:"Oil Pressure", displayShort:"Pressure", image:"Warm.svg", symbol:"Kpa", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "reg20003".toLowerCase():
            return { name:pointName, class:'fuel_level', display:"Fuel Level", displayShort:"Fuel", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return v;} };
          case "reg20005".toLowerCase():
            return { name:pointName, class:'battery', display:"Battery Voltage", displayShort:"Battery", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);}  };
          case "REG20130".toLowerCase():
            return { name:pointName, class:'load_a', display:"Load A", displayShort:"L1", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          case "REG20132".toLowerCase():
            return { name:pointName, class:'load_b', display:"Load B", displayShort:"L2", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          case "REG20134".toLowerCase():
            return { name:pointName, class:'load_c', display:"Load C", displayShort:"L3", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          
          case "REG20006".toLowerCase():
            return { name:pointName, class:'engine_speed', display:"Engine Speed", displayShort:"Speed", image:"Power.svg", symbol:"RPM", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v > 65500 ? 0 : (isNaN(0) ? 0 : v));} };

          case "REG20009".toLowerCase():
            return { name:pointName, class:'output_voltage_a', display:"Output Voltage L1", displayShort:"Output L1", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20011".toLowerCase():
            return { name:pointName, class:'output_voltage_b', display:"Output Voltage L2", displayShort:"Output L2", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20013".toLowerCase():
            return { name:pointName, class:'output_voltage_c', display:"Output Voltage L3", displayShort:"Output L3", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };            

          case "REG20016".toLowerCase():
            return { name:pointName, class:'mains_voltage_a', display:"Input Voltage L1", displayShort:"Input L1", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20018".toLowerCase():
            return { name:pointName, class:'mains_voltage_b', display:"Input Voltage L2", displayShort:"Input L2", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20020".toLowerCase():
            return { name:pointName, class:'mains_voltage_c', display:"Input Voltage L3", displayShort:"Input L3", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          
          case "REG20027".toLowerCase():
            return { name:pointName, class:'mode', display:"Generator Mode", displayShort:"Mode", image:"Power.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:3, showAs:'input'  };
          case "REG20001".toLowerCase():
            return { name:pointName, class:'coolant_temp', display:"Coolant Temperature", displayShort:"Coolant", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v > 32760 ? 0 : v);} };          
          case "REG20128".toLowerCase():
            return { name:pointName, class:'run_time', display:"Run Time", displayShort:"Run Time", image:"Warm.svg", symbol:"mins", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/60).toFixed(2);} };
        }
      }
      else {
        switch (pointName.toString().toLowerCase())
        {
          case "temp":
          case "temperature":
            return { name:pointName, class:'temperature', display:"Temperature", displayShort:"Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1 };
          case "humi":
          case "humidity":
            return { name:pointName, class:'humidity', display:"Humidity", displayShort:"Humi", image:"Humidity.svg", symbol:"%H", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2 };
          case "extpwr":
            return { name:pointName, class:'mains', display:"Mains Power Status", displayShort:"Mains", image:"Power.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:3  };
          // case "di0":
          //   return { display:"Mains Power Status", image:"Power.svg", states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}]  };

          case "di0":
            return { name:pointName, class:'door', display:"Door", displayShort:"Door", image:"Door.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Open',status:'danger'}, {min:1,max:1,text:'Closed',status:'success'}], data:{}, sortOrder:4  };
          case "di1":
            return { name:pointName, class:'smoke', display:"Smoke Sensor", displayShort:"Smoke", image:"Fire.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Fire',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:6  };
          case "di2":
            return { name:pointName, class:'leak', display:"Leak Sensor", displayShort:"Leak", image:"Raindrop.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Wet',status:'danger'}, {min:1,max:1,text:'Dry',status:'success'}], data:{}, sortOrder:5  };
          case "di3":
            return { name:pointName, class:'over_voltage', display:"Over Voltage", displayShort:"OVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Alarm',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:7  };
          case "di4":
            return { name:pointName, class:'under_voltage', display:"Under Voltage", displayShort:"UVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Alarm',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:8 };
          case "di5":
            return { name:pointName, class:'pdu_2', display:"PDU 02", displayShort:"PDU2", image:"Jacks.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:9  };
          case "di6":
            return { name:pointName, class:'pdu_1', display:"PDU 01", displayShort:"PDU", image:"Jacks.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:9  };
          case "di7":
            return { name:pointName, class:'ups_power', display:"UPS Power", displayShort:"UPS", image:"Low Battery.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:10  };
          
          case "co2":
            return { name:pointName, class:'co2', display:"Carbon Dioxide", displayShort:"CO2", image:"", symbol:"ppm", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11 };
          case "tvoc":
            return { name:pointName, class:'tvoc', display:"Total Volatile Organic Compounds", displayShort:"TVOC", image:"", symbol:"ppb", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11 };
          case "illumination":
            return { name:pointName, class:'illumination', display:"Illumination", displayShort:"Illumination", image:"", symbol:"lumen", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11 };
          case "pressure":
            return { name:pointName, class:'pressure', display:"Pressure", displayShort:"Pressure", image:"", symbol:"hPA", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:11 };

          default:
            return null;
        }
      }
    }
    else if (version == 3) {
      if (type && type.toLowerCase() == "generator") {
        switch (pointName.toString().toLowerCase()) {
          //Generator
          case "reg20019".toLowerCase():
            return { name:pointName, class:'oil_pressure', display:"Oil Pressure", displayShort:"Pressure", image:"Warm.svg", symbol:"Kpa", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20017".toLowerCase():
            return { name:pointName, class:'coolant_temp', display:"Coolant Temperature", displayShort:"Coolant", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v > 32760 ? 0 : v);} };
          case "reg20021".toLowerCase():
            return { name:pointName, class:'fuel_level', display:"Fuel Level", displayShort:"Fuel", image:"Warm.svg", symbol:"%", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return v;} };
          case "reg20024".toLowerCase():
            return { name:pointName, class:'battery', display:"Battery Voltage", displayShort:"Battery", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);}  };
          case "REG20023".toLowerCase():
            return { name:pointName, class:'engine_speed', display:"Engine Speed", displayShort:"Speed", image:"Power.svg", symbol:"RPM", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v > 65500 ? 0 : (isNaN(0) ? 0 : v));} };
          case "REG20007".toLowerCase():
            return { name:pointName, class:'output_voltage_a', display:"Output Voltage L1", displayShort:"Output L1", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20008".toLowerCase():
            return { name:pointName, class:'output_voltage_b', display:"Output Voltage L2", displayShort:"Output L2", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20009".toLowerCase():
            return { name:pointName, class:'output_voltage_c', display:"Output Voltage L3", displayShort:"Output L3", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:3, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };            
          case "REG20000".toLowerCase():
            return { name:pointName, class:'mains_voltage_a', display:"Input Voltage L1", displayShort:"Input L1", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20001".toLowerCase():
            return { name:pointName, class:'mains_voltage_b', display:"Input Voltage L2", displayShort:"Input L2", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          case "REG20002".toLowerCase():
            return { name:pointName, class:'mains_voltage_c', display:"Input Voltage L3", displayShort:"Input L3", image:"Warm.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:4, showAs:'gauge', format:function(v) { return (v/1).toFixed(2);} };
          
          case "REG20014".toLowerCase():
            return { name:pointName, class:'load_a', display:"Load A", displayShort:"L1", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          case "REG20015".toLowerCase():
            return { name:pointName, class:'load_b', display:"Load B", displayShort:"L2", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          case "REG20016".toLowerCase():
            return { name:pointName, class:'load_c', display:"Load C", displayShort:"L3", image:"Warm.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:5, showAs:'gauge', format:function(v) { return (v*1).toFixed(2);} };
          
          case "REG20034".toLowerCase():
            return { name:pointName, class:'mode', display:"Generator Mode", displayShort:"Mode", image:"Power.svg", symbol:"", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:11,max:11,text:'Alert',status:'danger'}, {min:0,max:10,text:'Normal',status:'success'}], data:{}, sortOrder:3, showAs:'input'  };
          case "REG20128".toLowerCase():
            return { name:pointName, class:'run_time', display:"Run Time", displayShort:"Run Time", image:"Warm.svg", symbol:"hrs", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1, showAs:'number', format:function(v) { return (v/1).toFixed(2);} };
        }
      }
      else {
        switch (pointName.toString().toLowerCase())
        {
          case "di1":
            return { name:pointName, class:'door', display:"Door", displayShort:"Door", image:"Door.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Open',status:'danger'}, {min:1,max:1,text:'Closed',status:'success'}], data:{}, sortOrder:4  };
          case "di3":
            return { name:pointName, class:'smoke', display:"Smoke Sensor", displayShort:"Smoke", image:"Fire.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Fire',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:6  };
          case "di2":
            return { name:pointName, class:'leak', display:"Leak Sensor", displayShort:"Leak", image:"Raindrop.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Wet',status:'danger'}, {min:1,max:1,text:'Dry',status:'success'}], data:{}, sortOrder:5  };
          case "di4":
            return { name:pointName, class:'over_voltage', display:"Over Voltage", displayShort:"OVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Alarm',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:7  };
          case "di5":
            return { name:pointName, class:'under_voltage', display:"Under Voltage", displayShort:"UVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Alarm',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:8 };
          case "di7":
            return { name:pointName, class:'pdu_2', display:"PDU 02", displayShort:"PDU2", image:"Jacks.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:9  };
          case "di6":
            return { name:pointName, class:'pdu_1', display:"PDU 01", displayShort:"PDU", image:"Jacks.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:9  };
          case "di0":
            return { name:pointName, class:'ups_power', display:"UPS Power", displayShort:"UPS", image:"Low Battery.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:10  };
          case "temp":
            return { name:pointName, class:'temperature', display:"Temperature", displayShort:"Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1 };
          case "humi":
            return { name:pointName, class:'humidity', display:"Humidity", displayShort:"Humi", image:"Humidity.svg", symbol:"%H", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2 };
          case "extpwr":
            return { name:pointName, class:'mains', display:"Mains Power Status", displayShort:"Mains", image:"Power.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:3  };
            
          default:
            return null;
        }
      }
    }
    else if (version == 4) {
      switch (pointName.toString().toLowerCase())
        {
          case "di1":
            return { name:pointName, class:'mains', display:"Mains Power Status", displayShort:"Mains", image:"Power.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:0,text:'Alert',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:3  };
          case "di3":
            return { name:pointName, class:'smoke', display:"Smoke Sensor", displayShort:"Smoke", image:"Fire.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Fire',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:6  };
          case "di2":
            return { name:pointName, class:'leak', display:"Leak Sensor", displayShort:"Leak", image:"Raindrop.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Wet',status:'danger'}, {min:1,max:1,text:'Dry',status:'success'}], data:{}, sortOrder:5  };
          case "di4":
            return { name:pointName, class:'over_voltage', display:"Over Voltage", displayShort:"OVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Alarm',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:7  };
          case "di5":
            return { name:pointName, class:'under_voltage', display:"Under Voltage", displayShort:"UVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Alarm',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:8 };
          case "di7":
            return { name:pointName, class:'pdu_2', display:"PDU 02", displayShort:"PDU2", image:"Jacks.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:9  };
          case "di6":
            return { name:pointName, class:'pdu_1', display:"PDU 01", displayShort:"PDU", image:"Jacks.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:9  };
          case "di0":
            return { name:pointName, class:'ups_power', display:"UPS Power", displayShort:"UPS", image:"Low Battery.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:10  };
          case "temp":
            return { name:pointName, class:'temperature', display:"Temperature", displayShort:"Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1 };
          case "humi":
            return { name:pointName, class:'humidity', display:"Humidity", displayShort:"Humi", image:"Humidity.svg", symbol:"%H", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2 };
            
            
          default:
            return null;
        }
    }
    else if (version == 5) {
      switch (pointName.toString().toLowerCase())
        {
          case "di1":
            return { name:pointName, class:'mains', display:"Mains Power Status", displayShort:"Mains", image:"Power.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:0,text:'Alert',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:3  };
          case "di3":
            return { name:pointName, class:'smoke', display:"Smoke Sensor", displayShort:"Smoke", image:"Fire.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Fire',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:6  };
          case "di2":
            return { name:pointName, class:'leak', display:"Leak Sensor", displayShort:"Leak", image:"Raindrop.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Wet',status:'danger'}, {min:1,max:1,text:'Dry',status:'success'}], data:{}, sortOrder:5  };
          case "di4":
            return { name:pointName, class:'over_voltage', display:"Over Voltage", displayShort:"OVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Alarm',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:7  };
          case "di5":
            return { name:pointName, class:'under_voltage', display:"Under Voltage", displayShort:"UVS", image:"Electricity Supply.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Alarm',status:'danger'}, {min:1,max:1,text:'Normal',status:'success'}], data:{}, sortOrder:8 };
          case "di7":
            return { name:pointName, class:'pdu_2', display:"PDU 02", displayShort:"PDU2", image:"Jacks.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:9  };
          case "di6":
            return { name:pointName, class:'pdu_1', display:"PDU 01", displayShort:"PDU", image:"Jacks.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:9  };
          case "di0":
            return { name:pointName, class:'ups_power', display:"UPS Power", displayShort:"UPS", image:"Low Battery.svg", type:'category', toolbox:{feature:{magicType:{type: []}}}, states:[{min:0,max:0,text:'Off',status:'danger'}, {min:1,max:1,text:'On',status:'success'}], data:{}, sortOrder:10  };
          case "temp":
            return { name:pointName, class:'temperature', display:"Temperature", displayShort:"Temp", image:"Warm.svg", symbol:"°C", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:1 };
          case "humi":
            return { name:pointName, class:'humidity', display:"Humidity", displayShort:"Humi", image:"Humidity.svg", symbol:"%H", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, markPoint: {data: [{ type: 'max', name: 'Max' },{ type: 'min', name: 'Min' }]},markLine: {data: [{ type: 'average', name: 'Avg' }]}, data:{}, sortOrder:2 };
          case "extpwr":
            return { name:pointName, class:'mains', display:"External Power", displayShort:"Ext. Power", image:"Power.svg", symbol:"V", type:'value', toolbox:{feature:{magicType:{type: ['line', 'bar']}}}, states:[{min:0,max:11,text:'Alert',status:'danger'}, {min:12,max:99999,text:'Normal',status:'success'}], data:{}, sortOrder:3  };
            
            
          default:
            return null;
        }
    }
  }

  getValueFromStates(measurement, deviceData = null) {
    if (measurement.detail) { //We have 2 ways of checking - in this case my measurement is the actual threshold
      var text = measurement.detail.format ? measurement.detail.format(measurement.lastValue) : measurement.lastValue;

      if (!measurement.detail.symbol && measurement.detail.states) {
        if (measurement.detail.states.filter(x => measurement.lastValue >= x.min && measurement.lastValue <= x.max).length > 0) {
          var state = measurement.detail.states.filter(x => measurement.lastValue >= x.min && measurement.lastValue <= x.max)[0];

          if (state) {
            text = state.text;
          }
        }
      }
    }
    else {
      var detail = null;

      if (deviceData)
        detail = this.getPointParms(measurement.name, deviceData.d_version, deviceData.d_type);

      var text = measurement.threshold ? measurement.threshold.lastValue : "";
      text = detail && detail.format ? detail.format(text) : text;
      
      if (measurement && !measurement.symbol && measurement.threshold && measurement.states) {
        if (measurement.states.filter(x => measurement.threshold.lastValue >= x.min && measurement.threshold.lastValue <= x.max).length > 0) {
          var state = measurement.states.filter(x => measurement.threshold.lastValue >= x.min && measurement.threshold.lastValue <= x.max)[0];

          if (state) {
            text = state.text;
          }
        }
      }
    }

    return text;
  }

  getAreaFriendlyName(areaId:string) : string {
    var areaNames = [
      {id:"imbizo",display:"Imbizo"},
      {id:"lobby",display:"Lobby"},
      {id:"gardens_a",display:"Gardens A"},
      {id:"canteen_b",display:"Canteen B"},
      {id:"canteen_a",display:"Canteen A"},
      {id:"reception",display:"Reception"},
      {id:"MeetingRoom_7",display:"Meeting Room 7"},
      {id:"MeetingRooms_1-3",display:"Meeting Rooms 1-3"},
      {id:"meetingrooms",display:"Meeting Rooms"},
      {id:"BCM",display:"BCM"},
      {id:"CommsOffices_SW",display:"Comms Offices SW"},
      {id:"Offices_NW_B",display:"Offices NW B"},
      {id:"Offices_SW_A",display:"Offices SW A"},
      {id:"Offices_SW_B",display:"Offices SW B"},
      {id:"PrintHub_NW_A",display:"PrintHub NW A"},
      {id:"Security",display:"Security"},
      {id:"PauseArea",display:"Pause Area"},
      {id:"Cafe",display:"Cafe"},
      {id:"Balcony",display:"Balcony"},

      {id:"PrintHub_SW_A",display:"PrintHub SW A"},
      {id:"PrintHub_NW_B",display:"PrintHub NW B"},
      {id:"PrintHub_SW_B",display:"PrintHub SW B"},
      {id:"ExecOffices_SW",display:"Executive Offices SW"},
      {id:"StoreRoom_SW",display:"Store Room SW"},

      {id:"PrintHub_SW",display:"PrintHub SW"},
      {id:"ExecOffices_NW",display:"Executive Offices NW"},
      {id:"Pause_Area",display:"AP Pause Area"},

      {id:"PrintHub_ASnR",display:"PrintHub ASnR"},
      {id:"Projects",display:"Projects"},
      {id:"PrintHub_HR",display:"PrintHub HR"},
      {id:"TrainingRoom",display:"Training Room"},
      {id:"TravelersLounge",display:"Traveler's Lounge"},

      {id:"Gym_Restrooms",display:"Gym Restrooms"},
      {id:"Offices_SW",display:"Offices SW"},

      {id:"Bar",display:"Bar"},
      {id:"Boardroom",display:"Boardroom"},
      {id:"ExecOffices02",display:"Executive Offices 02"},
      {id:"GenOfficesLegal",display:"General Offices Legal"},
      {id:"Meeting_Rooms",display:"Meeting Rooms"},
      {id:"OpenOffices",display:"Open Offices"},
      {id:"GeneralOffices",display:"General Offices"},
      
      {id:"PrintHub_Technical",display:"PrintHub Technical"},
      {id:"Exec_Offices",display:"Executive Offices"},
      {id:"Technical",display:"Technical"},
      {id:"Gardens_B",display:"Gardens B"},
      
    ];

    var found = null;
    areaNames.forEach(x => {
      if (!found) {
        if (areaId.toLowerCase().indexOf(x.id.toLowerCase()) > 0){
          //console.log(areaId.toLowerCase(), x.id.toLowerCase(), areaId.toLowerCase().indexOf(x.id.toLowerCase()) > 0, x.display);
          found = x;
        }
      }
    });

    if (found) {
      return found.display;
    }
    else {
      return areaId;
    }
  }
}