import {Component} from '@angular/core';
import { DeviceService } from './device.service';

import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>`
})
export class AppComponent {
  public view : any = {};
  constructor(private deviceService: DeviceService, private toastr: ToastrService, private route: ActivatedRoute) {
    //Access parm to determine if this is a direct link to full view
    this.route.queryParams
      .subscribe(params => {
        this.view.floor = params.floor;
        this.view.area = params.area;

        if (this.view.floor && this.view.area) {
          this.view.isDirectLink = true;
        }
      }
    );
  }

  public ngOnInit(): void {
    this.refreshDevices();

    this.deviceService.getDevices().then(devices => {
      if (!this.view.isDirectLink) {
        this.deviceService.deviceStateChange.subscribe(devices => {
          //console.log("check devices");
          if (this.view.devices) {
            devices = this.deviceService.deviceStateChanges(devices, this.view.devices);
    
            //console.log("compare devices", devices);
            //Alert devices that have changed states
            if (devices.critical) {
              devices.critical.filter(x=>x.stateChanged).forEach(device => {
                if (device.stateChanged) {
                  this.notify(device);
                }
              });
            }
            if (devices.warning) {
              devices.warning.filter(x=>x.stateChanged).forEach(device => {
                if (device.stateChanged) {
                  this.notify(device);
                }
              });
            }
            if (devices.healthy) {
              devices.healthy.filter(x=>x.stateChanged).forEach(device => {
                if (device.stateChanged) {
                  this.notify(device);
                }
              });
            }
            if (devices.offline) {
              devices.offline.filter(x=>x.stateChanged).forEach(device => {
                if (device.stateChanged) {
                  this.notify(device);
                }
              });
            }
          }
    
          this.view.devices = devices;
        });
      }

      this.view.devices = devices;
    });
  }

  public refreshDevices() {
    this.deviceService.getDevicesChanged().then(devices => {
      setTimeout(() => {
        this.refreshDevices();
      }, 10000);
    });
  }

  public notify(device) {
    var config : any = {
      timeOut: 10000,
      progressBar:true,
      progressAnimation:'decreasing'
    };

    if (device.d_health.toLowerCase() == 'critical') {
      this.toastr.error(`Changed from ${device.statePrevious.d_health} to ${device.d_health}`, device.d_name, config);
    }
    else if (device.d_health.toLowerCase() == 'warning') {
      this.toastr.warning(`Changed from ${device.statePrevious.d_health} to ${device.d_health}`, device.d_name, config);
    }
    else if (device.d_health.toLowerCase() == 'healthy') {
      this.toastr.success(`Changed from ${device.statePrevious.d_health} to ${device.d_health}`, device.d_name, config);
    }
    else if (device.d_health.toLowerCase() == 'offline') {
      this.toastr.info(`Changed from ${device.statePrevious.d_health} to ${device.d_health}`, device.d_name, config);
    }
  }
}